import MainLayout from "layout/MainLayout";
import PropTypes from "prop-types";
import NotFound from "views/NotFound";
import Statement from "views/Payments/Statement";
import MiniShopByID from "views/Vendors/MiniShop/MiniShopByID";
import {
  AVS,
  AirRiderByID,
  AllAirRider,
  AllD2C,
  AllEmerchants,
  AllMiniShop,
  AllOrders,
  AllPayments,
  AllRiders,
  AllSpaceOrders,
  AllSpaces,
  AllSubRiders,
  Annoucement,
  AnnoucementById,
  AppCustomers,
  BannerManagement,
  BannerManagementById,
  Blogs,
  BlogsById,
  BulkImage,
  BusinessCustomer,
  C2CAllOrders,
  C2COrdersById,
  C2CPricing,
  C2CShippment,
  C2CShippmentById,
  V_categories,
  Client,
  ClientById,
  Commission,
  CommissionType,
  CrmCustOrder,
  CrmProduct,
  CustomerById,
  Customers,
  Customers3P,
  D2CBusinessByID,
  Dashboard,
  EMerchantByID,
  FleetByID,
  FleetManagers,
  FrankCommissions,
  ItemByid,
  ListItem,
  ManageRoles,
  ManageUsers,
  NotificationReport,
  Orders,
  OrdersById,
  PlatFormFee,
  RegularPricing,
  Reporting,
  RiderByID,
  RoleDetails,
  SendNotification,
  ShippingRateTypes,
  ShippingRates,
  SpaceByID,
  SpaceCustomers,
  Suppliers,
  SuppliersById,
  SuppliersOrder,
  SuppliersOrderById,
  SupportAndChat,
  TmsManageRoutes,
  TmsSenderOrder,
  TmsTransporter,
  TransportFee,
  UserAcitvity,
  UserDetails,
  Attributes,
  V_categoriesById,
  AttributesByName,
  Specification,
  SpecificationById,
  AssignCatg,
  PayOut,
  PayOutHistory,
  PayOutHistoryById,
} from "./PageAsync";
import BusCustomerById from "views/Customers/BusinessCustomer/BusCustomerById";
import path from "path";
import { elements } from "chart.js";
// ==============================|| MAIN ROUTING ||============================== //

// export default MainRoutes;

export function MainAppRoutes({ classes, user }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout classes={classes} />,
    children: [
      {
        path: "/",
        element: <Dashboard classes={classes} />,
      },
      {
        path: "/dashboard",
        element: <Dashboard classes={classes} />,
      },
      {
        path: "/orders/e-merchant-orders",
        element: <AllOrders classes={classes} />,
      },
      {
        path: "/orders/frank-shop-orders",
        element: <C2CAllOrders classes={classes} />,
      },
      {
        path: "/orders/frank-shop-orders/:id",
        element: <C2COrdersById classes={classes} />,
      },

      {
        path: "/orders/space-orders",
        element: <AllSpaceOrders classes={classes} />,
      },
      {
        path: "/customers/3p-customers",
        element: <Customers3P classes={classes} />,
      },

      {
        path: "/customers/space-customers",
        element: <SpaceCustomers classes={classes} />,
      },

      {
        path: "/customers/app-customers",
        element: <AppCustomers classes={classes} />,
      },
      {
        path: "/customers",
        element: <BusinessCustomer classes={classes} />,
      },
      {
        path: "/customers/:id",
        element: <BusCustomerById classes={classes} />,
      },

      {
        path: "/transporters/fleet-managers",
        element: <FleetManagers classes={classes} />,
      },
      {
        path: "/transporters/fleet-managers/:id",
        element: <FleetByID classes={classes} />,
      },
      {
        path: "/transporters/sub-riders",
        element: <AllSubRiders classes={classes} />,
      },
      {
        path: "/transporters/riders",
        element: <AllRiders classes={classes} />,
      },
      {
        path: "/transporters/riders/:id",
        element: <RiderByID classes={classes} />,
      },

      {
        path: "/transporters/air-riders",
        element: <AllAirRider classes={classes} />,
      },
      {
        path: "/transporters/air-riders/:id",
        element: <AirRiderByID classes={classes} />,
      },
      // &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&7
      {
        path: "/vendors/e-merchants",
        element: <AllEmerchants classes={classes} />,
      },
      {
        path: "/vendors/e-merchants/:id",
        element: <EMerchantByID classes={classes} />,
      },
      {
        path: "/vendors/spaces",
        element: <AllSpaces classes={classes} />,
      },
      {
        path: "/vendors/spaces/:id",
        element: <SpaceByID classes={classes} />,
      },
      {
        path: "/vendors/d2c-business",
        element: <AllD2C classes={classes} />,
      },
      {
        path: "/vendors/d2c-business/:id",
        element: <D2CBusinessByID classes={classes} />,
      },
      {
        path: "/vendors/frank-shops",
        element: <AllMiniShop classes={classes} />,
      },
      {
        path: "/vendors/frank-shops/:id",
        element: <MiniShopByID classes={classes} />,
      },
      // &&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&

      {
        path: "/payments/all-payments",
        element: <AllPayments classes={classes} />,
      },
      {
        path: "/payments/statements",
        element: <Statement classes={classes} />,
      },

      {
        path: "/payments/shipping-rates",
        element: <ShippingRates classes={classes} />,
      },
      {
        path: "/payments/shipping-rates/:type/:name?/:rt_id?",
        element: <ShippingRateTypes classes={classes} />,
      },
      // {
      //   path: "/payments/commissions",
      //   element: <Commission classes={classes} />,
      // },
      // {
      //   path: "/payments/commissions/:type/:name?/:cm_id?",
      //   element: <CommissionType classes={classes} />,
      // },

      {
        path: "/management/notifications", //PUT s before push
        element: <SendNotification classes={classes} />,
      },
      {
        path: "/notifications/notification-tracking",
        element: <NotificationReport classes={classes} />,
      },
      {
        path: "/reporting",
        element: <Reporting classes={classes} />,
      },
      {
        path: "/support-chat",
        element: <SupportAndChat classes={classes} />,
      },

      {
        path: "/management/users",
        element: <ManageUsers classes={classes} />,
      },
      {
        path: "/management/users/details/:id?",
        element: <UserDetails classes={classes} />,
      },
      {
        path: "/management/roles",
        element: (
          <ManageRoles classes={classes} />

          // <PrivateRoute
          //   user={user}
          //   role_type="all"
          //   WrappedComponent={<ManageRoles classes={classes} />}
          // />
        ),
      },
      {
        path: "/management/roles/details/:id?",
        element: <RoleDetails classes={classes} />,
      },
      {
        path: "/management/banners",
        element: <BannerManagement classes={classes} />,
      },
      {
        path: "/management/banners/create",
        element: <BannerManagementById classes={classes} />,
      },
      {
        path: "/management/banners/manage/:id?",
        element: <BannerManagementById classes={classes} />,
      },

      {
        path: "/payments/announcements-pricing",
        element: <C2CPricing classes={classes} />,
      },
      {
        path: "/payments/transport-fees",
        element: <TransportFee classes={classes} />,
      },
      {
        path: "/payments/platform-fees",
        element: <PlatFormFee classes={classes} />,
      },
      {
        path: "/payments/commissions",
        element: <FrankCommissions classes={classes} />,
      },

      {
        path: "/blogs",
        element: <Blogs classes={classes} />,
      },
      {
        path: "/blogs/create",
        element: <BlogsById classes={classes} />,
      },
      {
        path: "/blogs/manage/:id?",
        element: <BlogsById classes={classes} />,
      },
      {
        path: "/orders/announcements",
        element: <C2CShippment classes={classes} />,
      },
      {
        path: "/orders/announcements/:id?",
        element: <C2CShippmentById classes={classes} />,
      },
      {
        path: "/management/items-list",
        element: <ListItem classes={classes} />,
      },
      {
        path: "/management/items-list/create",
        element: <ItemByid classes={classes} />,
      },
      {
        path: "/management/items-list/manage/:id?",
        element: <ItemByid classes={classes} />,
      },
      {
        path: "/bulk-images",
        element: <BulkImage classes={classes} />,
      },
      {
        path: "/management/users-activity",
        element: <UserAcitvity classes={classes} />,
      },
      /// new route for v2
      {
        path: "/oms/announcements",
        element: <Annoucement classes={classes} />,
      },
      {
        path: "/oms/announcements/:id?",
        element: <AnnoucementById classes={classes} />,
      },
      {
        path: "/oms/orders",
        element: <Orders classes={classes} />,
      },
      {
        path: "/oms/orders/:id?",
        element: <OrdersById classes={classes} />,
      },
      {
        path: "/oms/address-validation",
        element: <AVS classes={classes} />,
      },
      {
        path: "/wms/customers",
        element: <Customers classes={classes} />,
      },
      {
        path: "/wms/customers/:id?",
        element: <CustomerById classes={classes} />,
      },
      {
        path: "/wms/suppliers",
        element: <Suppliers classes={classes} />,
      },
      {
        path: "/wms/suppliers/:id?",
        element: <SuppliersById classes={classes} />,
      },
      {
        path: "/wms/supplier-orders",
        element: <SuppliersOrder classes={classes} />,
      },
      {
        path: "/wms/supplier-orders/:id?",
        element: <SuppliersOrderById classes={classes} />,
      },
      {
        path: "/crm/clients",
        element: <Client classes={classes} />,
      },

      {
        path: "/crm/clients/:id?",
        element: <ClientById classes={classes} />,
      },
      {
        path: "/crm/customer-orders",
        element: <CrmCustOrder classes={classes} />,
      },
      {
        path: "/crm/products.",
        element: <CrmProduct classes={classes} />,
      },
      {
        path: "/tms/transporters",
        element: <TmsTransporter classes={classes} />,
      },

      {
        path: "/tms/sender-orders",
        element: <TmsSenderOrder classes={classes} />,
      },
      {
        path: "/tms/manage-routes",
        element: <TmsManageRoutes classes={classes} />,
      },

      {
        path: "/wms/products",
        element: <CrmProduct classes={classes} />,
      },
      {
        path: "/regular-pricing",
        element: <RegularPricing classes={classes} />,
      },
      {
        path: "/categories",
        element: <V_categories classes={classes} />,
      },
      {
        path: "/categories/manage/:id?",
        element: <V_categoriesById classes={classes} />,
      },
      {
        path: "/categories/create",
        element: <V_categoriesById classes={classes} />,
      },
      {
        path: "/attribute",
        element: <Attributes classes={classes} />,
      },
      {
        path: "/attribute/create",
        element: <AttributesByName classes={classes} />,
      },
      {
        path: "/attribute/manage/:id?",
        element: <AttributesByName classes={classes} />,
      },
      {
        path: "/specification",
        element: <Specification classes={classes} />,
      },
      {
        path: "/specification/create",
        element: <SpecificationById classes={classes} />,
      },
      {
        path: "/specification/manage/:id?",
        element: <SpecificationById classes={classes} />,
      },
      {
        path: "/assign-category?",
        element: <AssignCatg classes={classes} />,
      },
      {
        path: "/payments/payouts/:id?",
        element: <PayOut classes={classes} />,
      },
      {
        path: "/payments/payouts-history",
        element: <PayOutHistory classes={classes} />,
      },
      {
        path: "/payments/payouts-history/:id?",
        element: <PayOutHistoryById classes={classes} />,
      },

      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };
  return MainRoutes;
}

// const PrivateRoute = ({ WrappedComponent, user }: any): any => {
//   // return <Forbidden />;
//   return <WrappedComponent />;
// };

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
