import * as React from "react";

// material-ui
import { Typography } from "@mui/material";

// project imports
// import NavGroup from "./NavGroup";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { all_modules } from "store/adminUserReducer";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import { restructureArray } from "utils/helper";
import NavCollapse from "../NavCollapse";
import NavItem from "../NavItem";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = ({ classes }: any) => {
  const dispatch = useDispatch<any>();
  const admin_slice = useSelector((state: RootState) => state.admin_users);
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      menuItems: [],
    }
  );

  // useEffect(() => {
  //   const user = getDataFromLocalStorage("user");
  //   const menuItems = restructureArray(user?.permissions ?? []);

  //   setState({
  //     menuItems,
  //   });

  //   // eslint-disable-next-line
  // }, [JSON.stringify(admin_slice.modules)]);

  useEffect(() => {
    // get_all_modules();
    const user = getDataFromLocalStorage("user");
    const menuItems = restructureArray(user?.permissions ?? []);

    setState({
      menuItems,
    });

    // eslint-disable-next-line
  }, []);

  const get_all_modules = () => {
    try {
      dispatch(
        all_modules({
          end_point: "/modules",
        })
      );
    } catch (error) {}
  };

  const navItems = state?.menuItems?.map((item: any) => {
    if(item.visibility === false) {
      return;
    }
    switch (item.type) {
      case "collapse":
        return (
          <NavCollapse classes={classes} key={item.id} menu={item} level={1} />
        );
      case "item":
        return (
          <NavItem classes={classes} key={item.id} item={item} level={1} />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }

    // switch (item.type) {
    //   case "group":
    //     return <NavGroup key={item.id} item={item} classes={classes} />;
    //   default:
    //     return (
    //       <Typography key={item.id} variant="h6" color="error" align="center">
    //         Menu Items Error
    //       </Typography>
    //     );
    // }
  });

  return <>{navItems}</>;
};

export default MenuList;
