import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors, key_to_label } from "utils/constants";
import {
  getStatusLabel,
  getStatusLabelColor,
  numberFormatWithoutCurrency,
} from "utils/helper";

export interface OrderState {
  all_orders: any;
  all_orders_data: any;
  selected_order: any;
  activity: boolean;

  delivery_types?: Array<any>;
  paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_space_orders: any;
  all_space_orders_data: any;
  all_space_orders_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  all_c2c_orders: any;
  all_c2c_orders_data: any;
  all_c2c_orders_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  c2c_orders_by_shop: any;
  c2c_orders_data_by_shop: any;
  c2c_orders_by_shop_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
}

interface APIParams {
  end_point: string;
  type?: string;
}

const initialState: OrderState = {
  all_orders: [],
  all_orders_data: [],
  all_c2c_orders: [],
  all_c2c_orders_data: [],
  c2c_orders_by_shop: [],
  c2c_orders_data_by_shop: [],
  all_space_orders: [],
  all_space_orders_data: [],

  delivery_types: [],
  selected_order: {},
  paginate_data: null,
  activity: true,
};

export const allOrderRequest = createAsyncThunk(
  "orders/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      // response = all_orders;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allSpaceOrderRequest = createAsyncThunk(
  "orders/space-order",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleOrder = createAsyncThunk(
  "orders/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
//
export const allC2cOrderRequest = createAsyncThunk(
  "c2c-order",
  async (data: any) => {
    try {
      const { end_point, ...payload } = data;

      let response = await apiServices.postFromApi(
        data.end_point,
       payload,
        "orders"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const C2csingleOrder = createAsyncThunk(
  "c2c-orders/by-customer/",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getDeliveryTypes = createAsyncThunk(
  "delivery-types",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const C2COrderByShop = createAsyncThunk(
  "order-by-shop",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        {
          shop_id: data?.shop_id,
          page: data.page,
          limit: data.limit,
          start_date: data.start_date,
          end_date: data.end_date,
        },
        "orders-by-shop"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
// dispatch
export const orderSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    setSelectedOrder: (state: OrderState, action: any) => {
      if (state.selected_order) {
        state.selected_order = action.payload;
      } else {
        state.selected_order = {};
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allOrderRequest.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleOrder.pending, (state, { payload }) => {})
      .addCase(singleOrder.fulfilled, (state, { payload }) => {})
      .addCase(allOrderRequest.fulfilled, (state, { payload }) => {
        let orders = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          orders.push([
            item?.id || "- - -",
            item?.order_placed_at
              ? moment(item?.order_placed_at).format("DD-MMM-YYYY")
              : "- - -",
            item?.order_type || "- - -",
            item?.delivery_type || "- - -",
            item?.is_storage ? "Yes" : "No",
            item?.customer_name || "- - -",
            {
              name: "€ " + numberFormatWithoutCurrency(item?.order_amount),
              style: {
                color:
                  item?.order_status === "cancelled_transporter" ||
                  item?.order_status === "cancelled_back_office_admin" ||
                  item?.order_status === "cancelled_store_admin" ||
                  item?.order_status === "cancelled_customer"
                    ? defaultLabelColors.red
                    : "",
              },
            },
            {
              name: getStatusLabel(item?.order_status as string) ?? "-",
              is_bool: true,
              color:
                item?.order_status === "pending"
                  ? getStatusLabelColor(item?.order_status as string)
                  : "black",
              style: {
                color:
                  getStatusLabelColor(item?.order_status as string) ?? "black",
              },
            },
            item?.transporter || "- - -",
            item?.origin || "- - -",
            item?.destination || "- - -",
          ]);
        }
        state.all_orders_data = payload?.data;
        state.all_orders = orders;
        state.paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      .addCase(getDeliveryTypes.pending, (state, { payload }) => {})
      .addCase(getDeliveryTypes.fulfilled, (state, { payload }) => {
        let d_types = payload.result?.sort((a: any, b: any) =>
          a.id < b.id ? -1 : 1
        );
        state.delivery_types = d_types;
      })
      .addCase(allSpaceOrderRequest.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allSpaceOrderRequest.fulfilled, (state, { payload }) => {
        let orders = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          orders.push([
            item?.id || "- - -",
            item?.order_placed_at
              ? moment(item?.order_placed_at).format("DD-MMM-YYYY")
              : "- - -",
            item?.order_type || "- - -",
            item?.customer_name || "- - -",
            {
              name:
                "€ " + numberFormatWithoutCurrency(item?.shipping_charges ?? 0),
              style: {
                color:
                  item?.order_status === "cancelled_transporter" ||
                  item?.order_status === "cancelled_back_office_admin" ||
                  item?.order_status === "cancelled_store_admin" ||
                  item?.order_status === "cancelled_customer"
                    ? defaultLabelColors.red
                    : "",
              },
            },
            {
              name: getStatusLabel(item?.order_status as string, true) ?? "-",
              is_bool: true,
              color:
                item?.order_status === "pending"
                  ? getStatusLabelColor(item?.order_status as string, true)
                  : "black",
              style: {
                color:
                  getStatusLabelColor(item?.order_status as string, true) ??
                  "black",
              },
            },
            item?.space_title || "- - -",
            item?.address || "- - -",
          ]);
        }
        state.all_orders_data = payload?.data;
        state.all_orders = orders;
        state.paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })

      .addCase(allC2cOrderRequest.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allC2cOrderRequest.fulfilled, (state, { payload }) => {
        let orders = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          orders.push([
            // `ODR-${item?.id}` || "- - -",
            { name: `ODR-${item?.id}` },
            item?.order_date
              ? //  moment(item?.order_date).format("DD/MM/YYYY HH:mm")
                moment(
                  `${item?.order_date}`.endsWith("Z")
                    ? item?.order_datef
                    : item?.order_date + "Z"
                ).format("DD/MM/YYYY ")
              : "- - -",
            item?.customer_name,
            `€ ${numberFormatWithoutCurrency(item?.total_order_price ?? 0)}` ||
              "0",
            `${item?.shop_name}`,
            {
              name: getStatusLabel(item?.order_status as string) ?? "-",
              is_bool: true,

              style: {
                color:
                  getStatusLabelColor(item?.order_status as string) ?? "black",
              },
            },

            `${
              item?.service_method
                ? key_to_label[item?.service_method]
                : "- - -"
            }`,
            `${item?.channel ?? "- - -"}`,

            `${item?.payment_method ?? "- - -"}`,
            `${item?.payment_status ?? "- - -" ? "Paid" : "Unpaid"}`,
            // `${item?.payment_method}`,

            `${item?.rider_name ?? "- - -"}`,

            `${item?.no_of_products ?? "- - -"}`,
            item?.deliver_or_cancelled_at
              ? //  moment(item?.deliver_or_cancelled_at).format("DD/MM/YYYY HH:mm")
                moment(
                  `${item?.deliver_or_cancelled_at}`.endsWith("Z")
                    ? item?.deliver_or_cancelled_at
                    : item?.deliver_or_cancelled_at + "Z"
                ).format("DD/MM/YYYY HH:mm")
              : "- - -",

            `${item?.machine_detail?.version ?? "- "} (${
              item?.machine_detail?.buildNumber ?? "-"
            })`,
            item?.machine_detail?.model_name
              ? item?.machine_detail?.model_name
              : `${item?.machine_detail?.deviceId ?? "- "} By ${
                  item?.machine_detail?.deviceName ?? " -"
                }`,
          ]);
        }
        state.all_c2c_orders_data = payload?.data;
        state.all_c2c_orders = orders;
        state.paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })

      .addCase(C2COrderByShop.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(C2COrderByShop.fulfilled, (state, { payload }) => {
        let orders = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          orders.push([
            `ODR-${item?.id}` || "- - -",
            // {item?.id},

            item?.order_date
              ? //  moment(item?.order_date).format("DD/MM/YYYY HH:mm")
                moment(
                  `${item?.order_date}`.endsWith("Z")
                    ? item?.order_date
                    : item?.order_date + "Z"
                ).format("DD/MM/YYYY ")
              : "- - -",
            item?.customer_name,
            `€ ${numberFormatWithoutCurrency(item?.total_order_price ?? 0)}` ||
              "0",
            `${item?.shop_name}`,
            {
              name: getStatusLabel(item?.order_status as string) ?? "-",
              is_bool: true,

              style: {
                color:
                  getStatusLabelColor(item?.order_status as string) ?? "black",
              },
            },

            `${
              item?.service_method
                ? key_to_label[item?.service_method]
                : "- - -"
            }`,
            `${item?.channel ?? "- - -"}`,

            `${item?.payment_method ?? "- - -"}`,
            `${item?.payment_status ?? "- - -" ? "Paid" : "Unpaid"}`,
            // `${item?.payment_method}`,
            item?.expected_delivery_to
              ? moment(item?.expected_delivery_to).format("DD/MM/YYYY HH:mm")
              : "- - -",
            `${item?.rider_name ?? "- - -"}`,

            `${item?.no_of_products ?? "- - -"}`,
            item?.deliver_or_cancelled_at
              ? //  moment(item?.deliver_or_cancelled_at).format("DD/MM/YYYY HH:mm")
                moment(
                  `${item?.deliver_or_cancelled_at}`.endsWith("Z")
                    ? item?.deliver_or_cancelled_at
                    : item?.deliver_or_cancelled_at + "Z"
                ).format("DD/MM/YYYY HH:mm")
              : "- - -",
          ]);
        }
        state.c2c_orders_data_by_shop = payload?.data;
        state.c2c_orders_by_shop = orders;
        state.c2c_orders_by_shop_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      });
  },
});

export const { setSelectedOrder } = orderSlice.actions;

export default orderSlice.reducer;
