import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { commissions, fleet_data } from "dummy-data";
import _ from "lodash";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";
import { numberFormatWithoutCurrency } from "utils/helper";

export interface CommissionState {
  all_commissions: any;
  all_commissions_data: any;
  paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  commission_users: any;
  commission_users_data: any;
  commission_users_paginate?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  transporters_for_commission: any;
  transporters_for_commission_data: any;

  activity: boolean;
}

interface APIParams {
  end_point: string;
  data?: any;
}

const initialState: CommissionState = {
  all_commissions: [],
  all_commissions_data: [],

  commission_users: [],
  commission_users_data: [],

  transporters_for_commission: [],
  transporters_for_commission_data: [],

  activity: false,
};

export const allCommssionsRequest = createAsyncThunk(
  "commissions/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "commission");
      // response = commissions;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const commissionUsers = createAsyncThunk(
  "commissions/all/:rider_id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAllTransporters = createAsyncThunk(
  "commissions/transporters",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const commissionSlicer = createSlice({
  name: "commissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(allCommssionsRequest.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allCommssionsRequest.fulfilled, (state, { payload }) => {
        let table_data = [];
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          let basic_1 = item?.commission_details[0];
          let basic_2 = item?.commission_details[1];
          const id = item?.id;
          const prefix = "COM-"; // Assuming the prefix is constant

          // Get the portion of the ID after the prefix
          const idWithoutPrefix = id.slice(prefix.length);

          // Remove the date portion "20230911" (8 characters) from the ID
          const trimmedId = idWithoutPrefix.slice(8);

          table_data.push([
            `COM-${trimmedId}` ?? "",
            item?.commission_name ? _.startCase(item?.commission_name) : "N/A",
            item?.commission_type ?? "N/A",
            basic_1?.percent_or_fix === "%"
              ? `${numberFormatWithoutCurrency(
                  basic_1?.commission_amount ?? 0
                )} %`
              : `€ ${numberFormatWithoutCurrency(
                  basic_1?.commission_amount ?? 0
                )}`,

            basic_2?.percent_or_fix === "%"
              ? `${numberFormatWithoutCurrency(
                  basic_2?.commission_amount ?? 0
                )} %`
              : `€ ${numberFormatWithoutCurrency(
                  basic_2?.commission_amount ?? 0
                )}`,

            {
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active
                ? defaultLabelColors.green
                : defaultLabelColors.red,
              style: {
                color: item?.is_active
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
              },
            },
            item?.applicable_date
              ? moment(item?.applicable_date).format("DD MMM YYYY")
              : "N/A",
            item?.expiry_date
              ? moment(item?.expiry_date).format("DD MMM YYYY")
              : "N/A",
            `${
              Number(item?.no_of_users) > 0
                ? `${item?.no_of_users} ${item?.commission_type}(s)`
                : "N/A"
            }`,
            moment(item?.created_at).format("DD MMM YYYY hh:mm A"),
            item?.created_by ?? "- - -",
            item?.updated_by
              ? moment(item?.updated_at).format("DD MMM YYYY hh:mm A")
              : "- - -",

            item?.updated_by ? item?.updated_by : "- - -",
            {
              name: item?.id,
              hidden: true,
            },
          ]);
        }
        state.all_commissions = table_data;
        state.all_commissions_data = payload?.data;
        state.paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      .addCase(commissionUsers.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(commissionUsers.fulfilled, (state, { payload }) => {
        const _commission = payload?.data;
        let table_data = [];
        for (let index = 0; index < _commission?.length; index++) {
          const item = _commission?.[index];
          table_data.push([
            item?.resource_id ?? "N/A",
            item?.resource_name ?? "N/A",
            item?.resource_phone ?? "N/A",
            item?.resource_location ?? "N/A",
            item?.applied_date
              ? moment(item?.applied_date).format("DD MMM YYYY")
              : "N/A",
            item?.applied_by ?? "N/A",
          ]);
        }
        state.commission_users = table_data;
        state.commission_users_data = payload?.data;
        state.commission_users_paginate = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      .addCase(getAllTransporters.pending, (state, { payload }) => {})
      .addCase(getAllTransporters.fulfilled, (state, { payload }) => {
        const _commission = payload?.data;
        let table_data = [];
        for (let index = 0; index < _commission?.length; index++) {
          const item = _commission?.[index];
          const isPresent = state.commission_users_data.filter(
            (i: any) => i.resource_id === item?.id
          );
          if (isPresent < 0) {
            table_data.push([
              item?.id ?? "N/A",
              item?.full_name ?? "N/A",
              item?.phone ?? "N/A",
              item?.locations ?? "N/A",
              item?.commission_name ?? "N/A",
              item?.applied_date
                ? moment(item?.applied_date).format("DD MMM YYYY")
                : "N/A",
            ]);
          }
        }
        state.transporters_for_commission = table_data;
        state.transporters_for_commission_data = payload?.data;

        state.activity = false;
      });
  },
});

// export const {} = paymentSlicer.actions;

export default commissionSlicer.reducer;
