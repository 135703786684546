import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";

export interface OrderState {
  all_users: any;
  all_users_data: any;
  activity: boolean;

  delivery_types?: Array<any>;
  paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_roles: any;
  all_roles_data: any;

  roles_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  role_name: string;
  role_description: string;
  role_status: any;

  modules: any;
}

interface APIParams {
  end_point: string;
  type?: string;
}

const initialState: OrderState = {
  all_users: [],
  all_users_data: [],

  all_roles: [],
  all_roles_data: [],

  modules: [],

  delivery_types: [],
  paginate_data: null,

  role_name: "",
  role_description: "",
  role_status: null,

  activity: true,
};

export const getAllUsers = createAsyncThunk(
  "users/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const getAllRoles = createAsyncThunk(
  "roles/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "roles");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_modules = createAsyncThunk(
  "modules/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "modules");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const orderSlice = createSlice({
  name: "admin_users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(getAllUsers.fulfilled, (state, { payload }) => {
        let all_users = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          all_users.push([
            item?.id || "N/A",
            `${item?.first_name ?? ""} ${item?.last_name ?? ""}`,
            item?.email ?? "",
            {
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active
                ? defaultLabelColors.green
                : defaultLabelColors.red,
              style: {
                color: item?.is_active
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
              },
            },
            item?.added_by ?? "N/A",
            item?.created_at
              ? 
              // moment(item?.created_at).format("DD MMM YYYY")
              moment(
                `${item?.created_at}`.endsWith('Z')
                  ? item?.created_at
                  : item?.created_at + 'Z',
              ).format('DD/MM/YYYY HH:mm')

              : "N/A",
            item?.modified_by ?? "N/A",
            item?.modified_by
              ? 
              moment(item?.updated_at).format("DD/MMM/YYYY")

              : "N/A",
          ]);
        }
        state.all_users_data = payload?.data;
        state.all_users = all_users;
        state.paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      .addCase(getAllRoles.pending, (state, { payload }) => {})
      .addCase(getAllRoles.fulfilled, (state, { payload }) => {
        let all_roles = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          all_roles.push([
            item?.id || "N/A",
            item?.role_name ?? "N/A",
            {
              name: item?.description ?? "N/A",
              ui: (
                <div
                  title={item?.description ?? ""}
                  style={{
                    flex: 1,
                    display: "-webkit-box",
                    maxWidth: 200,
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                  }}
                >
                  {item?.description ?? "N/A"}
                </div>
              ),
            },
            {
              name: item?.is_active ? "Active" : "Inactive",
              is_bool: true,
              color: item?.is_active
                ? defaultLabelColors.green
                : defaultLabelColors.red,
              style: {
                color: item?.is_active
                  ? defaultLabelColors.green
                  : defaultLabelColors.red,
              },
            },
            item?.added_by ?? "N/A",
            item?.created_at
              ? 
              // moment(item?.created_at).format("DD/MM/YYYY")
              moment(
                `${item?.created_at}`.endsWith('Z')
                  ? item?.created_at
                  : item?.created_at + 'Z',
              ).format('DD/MM/YYYY HH:mm')

              : "N/A",
            item?.modified_by ?? "N/A",
            item?.modified_by
              ? moment(item?.updated_at).format("DD/MM/YYYY")
              : "N/A",
          ]);
        }
        state.all_roles_data = payload?.data?.map((i: any) => {
          return { ...i, label: i.role_name, value: i?.id };
        });
        state.all_roles = all_roles;
        state.roles_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      .addCase(all_modules.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(all_modules.fulfilled, (state, { payload }) => {
        state.modules = payload?.data ?? [];
        state.activity = false;
      });
  },
});

// eslint-disable-next-line
export const {} = orderSlice.actions;

export default orderSlice.reducer;
