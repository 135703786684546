import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { allInvoices, statements } from "dummy-data";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { numberFormatWithoutCurrency } from "utils/helper";

export interface PaymentState {
  selected_invoice: any;
  all_invoices: any;
  all_invoices_data: any;

  selected_payment: any;
  all_payments: any;
  all_payments_data: any;
  all_payments_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  all_statement: any;
  all_statement_data: any;

  panels: {
    merchant: boolean;
    c2C_shipping: boolean;
    air_shipping: boolean;
    space_storage: boolean;
  };
  is_editable: boolean;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: PaymentState = {
  selected_invoice: {},
  all_invoices: [],
  all_invoices_data: [],

  selected_payment: {},
  all_payments: [],
  all_payments_data: [],

  all_statement: [],
  all_statement_data: [],

  panels: {
    merchant: true,
    c2C_shipping: true,
    air_shipping: true,
    space_storage: true,
  },

  is_editable: true,
  activity: false,
};

export const allInvoiceRequest = createAsyncThunk(
  "payments/invoices",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      response = allInvoices;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allPaymentRequest = createAsyncThunk(
  "payments/payments",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allStatementRequest = createAsyncThunk(
  "payments/statements",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      response = statements;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const paymentSlicer = createSlice({
  name: "payments",
  initialState,
  reducers: {
    setSelectedInvoice: (state: PaymentState, action: any) => {},
    setSelectedPayment: (state: PaymentState, action: any) => {},
    handleToggleSwitch: (state: PaymentState, action: any) => {
      state.is_editable = action.payload;
    },
    handleToggleMerchant: (state: PaymentState, action: any) => {
      state.panels.merchant = action.payload;
    },
    handleToggleC2C: (state: PaymentState, action: any) => {
      state.panels.c2C_shipping = action.payload;
    },
    handleToggleAir: (state: PaymentState, action: any) => {
      state.panels.air_shipping = action.payload;
    },
    handleToggleSpace: (state: PaymentState, action: any) => {
      state.panels.space_storage = action.payload;
    },
    toggleRatesPanels: (state: PaymentState, action: any) => {
      state.panels = {
        merchant: action.payload,
        c2C_shipping: action.payload,
        air_shipping: action.payload,
        space_storage: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allInvoiceRequest.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allInvoiceRequest.fulfilled, (state, { payload }) => {
        let invoices = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          invoices.push([
            item?.id || "- - -",
            item?.date || "- - -",
            item?.transaction_type || "- - -",
            item?.entity_type || "- - -",
            item?.cust_name || "- - -",
            item?.total_orders || "- - -",
            item?.amount || "- - -",
            {
              name: item?.status || "- - -",
              style: { color: item?.status !== "Paid" ? "#f10000" : "black" },
            },
          ]);
        }
        state.all_invoices = invoices;
        state.all_invoices_data = payload?.data;
        state.activity = false;
      })
      .addCase(allPaymentRequest.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allPaymentRequest.fulfilled, (state, { payload }) => {
        let payments = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          const id = item?.id;
          const prefix = "PYT-"; // Assuming the prefix is constant

          const idWithoutPrefix = id.slice(prefix.length);

          const trimmedId = idWithoutPrefix.slice(6);

          payments.push([
            prefix + trimmedId || "N/A",
            item?.payment_date
              ? moment(item?.payment_date).format("DD MMM YYYY")
              : "N/A",
            item?.payment_mode ?? "N/A",
            item?.bank_title ?? "N/A",
            item?.transaction_id ?? "N/A",
            "€ " +
              numberFormatWithoutCurrency(Number(item?.payment_amount ?? 0)),
            item?.created_by_user ?? "N/A",
            item?.created_at
              ? moment(item?.created_at).format("DD MMM YYYY HH:mm")
              : "N/A",
            {
              name: item?.id,
              hidden: true,
            },
          ]);
        }
        state.all_payments = payments;
        state.all_payments_data = payload?.data;
        console.log(payload?.total_count)
        state.all_payments_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      })
      .addCase(allStatementRequest.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allStatementRequest.fulfilled, (state, { payload }) => {
        let statements = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];

          statements.push([
            item?.date || "- - -",
            item?.transaction || "- - -",
            item?.entity_type || "- - -",
            item?.entity_name || "- - -",
            { name: item?.debit || "- - -", alignment: "right" },
            { name: item?.credit || "- - -", alignment: "right" },
            { name: item?.balance || "- - -", alignment: "right" },
          ]);
        }
        state.all_statement = statements;
        state.all_statement_data = payload?.data;
        state.activity = false;
      });
  },
});

export const {
  setSelectedInvoice,
  setSelectedPayment,
  handleToggleMerchant,
  handleToggleC2C,
  handleToggleAir,
  handleToggleSpace,
  toggleRatesPanels,
  handleToggleSwitch,
} = paymentSlicer.actions;

export default paymentSlicer.reducer;
