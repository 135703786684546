import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fleet_data } from "dummy-data";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";
import { numberFormatWithoutCurrency } from "utils/helper";

export interface TrasnporterState {
  single_fleet: any;
  all_fleet_managers: any;
  all_fleet_managers_data: any;
  fleet_manager_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  single_sub_rider: any;
  all_sub_riders: any;
  all_sub_riders_data: any;
  sub_rider_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  single_rider: any;
  all_riders: any;
  all_riders_data: any;
  rider_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  single_air_rider: any;
  all_air_riders: any;
  all_air_riders_data: any;

  panels: {
    profile: any;
    status: any;
    earnings: any;
    vehicles: any;
    sub_riders: any;
  };

  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: TrasnporterState = {
  single_fleet: {},
  all_fleet_managers: [],
  all_fleet_managers_data: [],

  single_sub_rider: {},
  all_sub_riders: [],
  all_sub_riders_data: [],

  single_rider: {},
  all_riders: [],
  all_riders_data: [],

  single_air_rider: {},
  all_air_riders: [],
  all_air_riders_data: [],

  panels: {
    profile: true,
    status: true,
    earnings: true,
    vehicles: true,
    sub_riders: true,
  },

  activity: true,
};

export const allFleetManagers = createAsyncThunk(
  "/admin/fleetmanagers",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allSubRiders = createAsyncThunk(
  "admin/subriders",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "subriders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allRiders = createAsyncThunk(
  "admin/riders",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "rider");
      // response = fleet_data;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const allAirRiders = createAsyncThunk(
  "transporter/air-riders",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "orders");
      response = fleet_data;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleFleet = createAsyncThunk(
  "/admin/fleetmanagers-or-riders/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "fleet-manager"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleRider = createAsyncThunk(
  "/admin/riders/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "riders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleSubRider = createAsyncThunk(
  "/admin/sub-riders/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "sub-riders");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleAirRider = createAsyncThunk(
  "transporter/air-rider/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        "countries/web" || data.end_point,
        "orders"
      );
      response = fleet_data.data[0];
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const transporterSlice = createSlice({
  name: "transporter",
  initialState,
  reducers: {
    clearAllTransporters: (state: TrasnporterState, action: any) => {},
    handleChangeProfile: (state: TrasnporterState, action: any) => {
      state.panels.profile = action.payload;
    },
    handleChangeStatus: (state: TrasnporterState, action: any) => {
      state.panels.status = action.payload;
    },
    handleChangeEarnings: (state: TrasnporterState, action: any) => {
      state.panels.earnings = action.payload;
    },
    handleChangeVehicles: (state: TrasnporterState, action: any) => {
      state.panels.vehicles = action.payload;
    },
    handleChangeSubRiders: (state: TrasnporterState, action: any) => {
      state.panels.sub_riders = action.payload;
    },
    togglePanels: (state: TrasnporterState, action: any) => {
      state.panels = {
        profile: action.payload,
        status: action.payload,
        earnings: action.payload,
        vehicles: action.payload,
        sub_riders: action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(allFleetManagers.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allFleetManagers.fulfilled, (state, { payload }) => {
        let fleet_managers = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          let subrider_count = item?.is_also_rider
            ? Number(item?.total_sub_riders) - 1
            : Number(item?.total_sub_riders);
          fleet_managers.push([
            item?.id || "- - -",
            item?.full_name || "- - -",
            item?.locations || "- - -",
            item?.phone ?? "- - -",
            {
              value: item?.status,
              name: item?.status ? "Active" : "Inactive",
              is_bool: true,
              color: item?.status ? defaultLabelColors.main_blue : "gray",
            },
            item?.joining_date
              ? moment(item?.joining_date).format("DD-MMM-YYYY")
              : "- - -",
            numberFormatWithoutCurrency(Number(item?.total_vehicles ?? 0)),
            numberFormatWithoutCurrency(Number(subrider_count)),
            item?.is_also_rider ? "Yes" : "No",
          ]);
        }
        state.all_fleet_managers_data = payload?.data;
        state.all_fleet_managers = fleet_managers;
        state.fleet_manager_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      })
      .addCase(singleFleet.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleFleet.fulfilled, (state, { payload }) => {
        state.single_fleet = payload?.result;
        state.activity = false;
      })
      .addCase(allSubRiders.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allSubRiders.fulfilled, (state, { payload }) => {
        let allSubRiders = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          allSubRiders.push([
            item?.id || "- - -",
            item?.full_name || "- - -",
            item?.locations || "- - -",
            item?.phone ?? "- - -",
            {
              value: item?.status,
              name: item?.status ? "Active" : "Inactive",
              is_bool: true,
              color: item?.status ? "#3026f1" : "gray",
            },
            {
              value: item?.is_verified,
              name: item?.is_verified ? "Verified" : "Not Verified",
              is_bool: true,
              color: item?.is_verified ? "#3026f1" : "#f10000",
            },
            item?.created_at
              ? moment(item?.created_at).format("DD-MMM-YYYY")
              : "- - -",
            item?.fleet_manager_name || "- - -",
          ]);
        }
        state.all_sub_riders = allSubRiders;
        state.all_sub_riders_data = payload?.data;
        state.sub_rider_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      })
      .addCase(singleSubRider.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleSubRider.fulfilled, (state, { payload }) => {
        state.single_sub_rider = payload?.result;
        state.activity = false;
      })
      .addCase(allRiders.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allRiders.fulfilled, (state, { payload }) => {
        let allRiders = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          allRiders.push([
            item?.id || "- - -",
            item?.full_name || "- - -",
            item?.locations || "- - -",
            item?.phone ?? "- - -",
            {
              value: item?.status,
              name: item?.status ? "Active" : "Inactive",
              is_bool: true,
              color: item?.status ? "#3026f1" : "gray",
            },
            {
              value: item?.is_verified,
              name: item?.is_verified ? "Verified" : "Not Verified",
              is_bool: true,
              color: item?.is_verified ? "#3026f1" : "#f10000",
            },
            item?.created_at
              ? moment(item?.created_at).format("DD-MMM-YYYY")
              : "- - -",
            item?.is_also_rider ? "Yes" : "No",
          ]);
        }
        state.all_riders = allRiders;
        state.all_riders_data = payload?.data;
        state.rider_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };

        state.activity = false;
      })
      .addCase(singleRider.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleRider.fulfilled, (state, { payload }) => {
        state.single_rider = payload?.result;
        state.activity = false;
      })
      .addCase(allAirRiders.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allAirRiders.fulfilled, (state, { payload }) => {
        let airRiders = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          airRiders.push([
            item?.id || "- - -",
            item?.name || "- - -",
            item?.city_country || "- - -",
            `${item?.phone_code} ${item?.phone}`,
            {
              value: item?.status,
              name: item?.status ? "Active" : "Inactive",
              is_bool: true,
              color: item?.status ? "#3026f1" : "gray",
            },
            {
              value: item?.verified,
              name: item?.verified ? "Active" : "Inactive",
              is_bool: true,
              color: item?.verified ? "#3026f1" : "#f10000",
            },
            item?.date_joined || "- - -",
          ]);
        }
        state.all_air_riders = airRiders;
        state.all_air_riders_data = payload?.data;
        state.activity = false;
      })
      .addCase(singleAirRider.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleAirRider.fulfilled, (state, { payload }) => {
        state.single_air_rider = payload;
        state.activity = false;
      });
  },
});

export const {
  clearAllTransporters,
  handleChangeProfile,
  handleChangeStatus,
  handleChangeEarnings,
  handleChangeVehicles,
  handleChangeSubRiders,
  togglePanels,
} = transporterSlice.actions;

export default transporterSlice.reducer;
