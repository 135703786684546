import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { reporting_data } from "dummy-data";
import apiServices from "services/RequestHandler";

export interface ReportingState {
  reporting_data: any;
  all_reporting_data: any;

  reporting_tool: boolean;
  reporting_table: boolean;

  activity: boolean;
}

const initialState: ReportingState = {
  reporting_data: [],
  all_reporting_data: [],

  reporting_tool: true,
  reporting_table: false,

  activity: false,
};

export const sendReportRequest = createAsyncThunk(
  "reporting/request",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        "countries/web",
        data,
        "orders"
      );
      response = reporting_data;
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const reportingSlicer = createSlice({
  name: "reporting",
  initialState,
  reducers: {
    handleToggleReportingTool: (state: ReportingState, action: any) => {
      state.reporting_tool = action.payload;
    },
    handleToggleReportingData: (state: ReportingState, action: any) => {
      state.reporting_table = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendReportRequest.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(sendReportRequest.fulfilled, (state, { payload }) => {
        let report_data = [] as any;
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          report_data.push([
            item?.entity_id || "- - -",
            item?.entity_type || "- - -",
            item?.entity_name || "- - -",
            item?.order_type || "- - -",
            item?.order_date || "- - -",
            item?.order_amount || "- - -",
            item?.dest_country || "- - -",
            item?.dest_city || "- - -",
          ]);
        }
        state.reporting_data = report_data;
        state.all_reporting_data = payload?.data;
        state.activity = false;
      });
  },
});

export const { handleToggleReportingTool, handleToggleReportingData } =
  reportingSlicer.actions;

export default reportingSlicer.reducer;
