import { Grid } from "@mui/material";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { RootState } from "store";
import { setTitle } from "store/breadcrumbReducer";
import { allStatementRequest } from "store/paymentReducers";
import Button from "ui-components/Button";
import Datatable from "ui-components/Datatable";
import DateRangeFilter from "ui-components/DateRangeFilter";
import LoadingComponent from "ui-components/LoadinComponent";
import SelectField from "ui-components/SelectField";

export default function Statement(props: any) {
  const { classes } = props;
  const dispatch = useDispatch<any>();
  const paymentReducer = useSelector((state: RootState) => state.payments);

  useEffect(() => {
    getAllStatements();
    // eslint-disable-next-line
  }, []);

  const getAllStatements = async () => {
    try {
      dispatch(setTitle("Statement" as any));
      dispatch(allStatementRequest({ end_point: "countries/web" }));
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.main_layout_padding}>
      <LoadingComponent activity={paymentReducer.activity} />
      <Datatable
        columns={[
          { name: "Transaction Date", alignment: "left" },
          { name: "Transaction", alignment: "left" },
          { name: "Entity Type", alignment: "left" },
          { name: "Entity Name", alignment: "left" },
          { name: "Debit", alignment: "right" },
          { name: "Credit", alignment: "right" },
          { name: "Balance", alignment: "right" },
        ]}
        rows={paymentReducer?.all_statement || []}
        min_width={"100%"}
        ariaLabel={"invoice-table"}
        searchSize={"small"}
        classes={classes}

        enablePagination={false}
        previous_count={0}
        current_count={4}
        total_records={4}
        count={1}
        show_header={false}
        CustomHeader={() => (
          <Grid container spacing={2}>
            <Grid xs={12} sm={6} md={5} item style={{ display: "flex" }}>
              <DateRangeFilter
                disableClearable={true}
                placeholder={"Filter Type"}
              />
              &emsp;
              <SelectField
                value={{ label: "All Entities", value: 12345 }}
                options={[
                  { label: "All Entities", value: 12345 },
                  { label: "Fleet Manager", value: 12346 },
                  { label: "Rider", value: 12347 },
                  { label: "Sub-Rider", value: 12348 },
                  { label: "Air Rider", value: 12349 },
                  { label: "E-merchant", value: 12350 },
                ]}
                classes={classes}
                disableClearable
              />
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={3}
              item
              style={{
                display: "flex",
              }}
              className={classes.dialog_description_2}
            >
              <div>
                <div
                  className={classNames(
                    classNames(
                      classes.dialog_footer_info,
                      "RobotoCondensed-Medium"
                    ),
                    "RobotoCondensed-Medium"
                  )}
                >
                  Total Debit: € 23,650
                </div>
                <div
                  className={classNames(
                    classes.dialog_footer_info,
                    "RobotoCondensed-Medium"
                  )}
                >
                  Total Credit: € 19,450
                </div>
              </div>
              <div style={{ marginLeft: "10%" }}>
                <div
                  style={{ fontStyle: "italic" }}
                  className={classNames(
                    classes.dialog_footer_info,
                    "RobotoCondensed-Medium"
                  )}
                >
                  Previous Balance: € 52,500
                </div>
                <div
                  className={classNames(
                    classes.dialog_footer_info,
                    "RobotoCondensed-Medium"
                  )}
                >
                  This Balance: € 1,250,000
                </div>
              </div>
            </Grid>
            <Grid
              xs={12}
              sm={6}
              md={4}
              item
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => {}}
                value={"Export as Excel"}
                classesNames={classNames(
                  classes.button_width,
                  classes.contained_button
                )}
                style={{ marginRight: 20 }}
              />
              <Button
                onClick={() => {}}
                value={"Print to PDF"}
                classesNames={classNames(
                  classes.button_width,
                  classes.contained_button
                )}
                style={{ marginRight: 20 }}
              />
            </Grid>
          </Grid>
        )}
      />
    </div>
  );
}
