export const all_orders = {
  status: 200,
  data: [
    {
      order_id: "1234567891",
      order_date: "11 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Henry Patrick",
      amount: "€ 10.00",
      order_status: "Waiting",
      rider: "- - -",
      origin: "Paris, FRA",
      destination: "Paris, FRA",
      item_name: "Mugs",
    },
    {
      order_id: "123412342854",
      order_date: "11 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Flex",
      isStorage: true,
      customer: "Arthur Louis",
      amount: "€ 20.00",
      order_status: "Delivered",
      rider: "Abge Brett",
      origin: "Paris, FRA",
      destination: "Lyon, FRA",
      item_name: "Books",
    },
    {
      order_id: "123412342853",
      order_date: "11 Nov 2022",
      order_type: "C2C Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Paul Baker",
      amount: "€ 12.50",
      order_status: "Waiting",
      rider: "- - -",
      origin: "Bordeauz, FRA",
      destination: "Bordeauz, FRA",
      item_name: "Makeup Box",
    },
    {
      order_id: "123412342852",
      order_date: "10 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Harris Noel",
      amount: "€ 10.00",
      order_status: "In-Progress",
      rider: "Eric Strappe",
      origin: "Paris, FRA",
      destination: "Frankfurt, DEU",
      item_name: "3 x Black Shirts",
    },
    {
      order_id: "123412342851",
      order_date: "10 Nov 2022",
      order_type: "C2C Shipping",
      delivery_type: "Green",
      isStorage: true,
      customer: "Nadia Loren",
      amount: "€ 16.00",
      order_status: "In-Progress",
      rider: "Steve Evra",
      origin: "Marseilles, FRA",
      destination: "Marseilles, FRA",
      item_name: "Giftbox",
    },
    {
      order_id: "123412342850",
      order_date: "09 Nov 2022",
      order_type: "Storage Only",
      delivery_type: null,
      isStorage: null,
      customer: "Quinton Cedric",
      amount: "€ 12.00",
      order_status: "Delivered",
      rider: "Roger Gallas",
      origin: "Lyon, FRA",
      destination: "Lyon, FRA",
      item_name: "Fragnances",
    },
    {
      order_id: "123412342849",
      order_date: "09 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "George Alice",
      amount: "€ 10.00",
      order_status: "Waiting",
      rider: "- - -",
      origin: "Milan, ITA",
      destination: "Milan, ITA",
      item_name: "Mobile",
    },
    {
      order_id: "123412342848",
      order_date: "09 Nov 2022",
      order_type: "C2C Air Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Henri Noel",
      amount: "€ 25.00",
      order_status: "Delivered",
      rider: "Roger Gallas",
      origin: "Paris, FRA",
      destination: "Paris, FRA",
      item_name: "Laptop",
    },
    {
      order_id: "123412342847",
      order_date: "08 Nov 2022",
      order_type: "C2C Shipping",
      delivery_type: "Classic",
      isStorage: false,
      customer: "Lina Michelle",
      amount: "€ 18.50",
      order_status: "Cancelled",
      rider: "- - -",
      origin: "Barcelona, ESP",
      destination: "Barcelona, ESP",
      item_name: "Mouse",
    },
    {
      order_id: "123412342846",
      order_date: "08 Nov 2022",
      order_type: "Store Shipping",
      delivery_type: "Classic",
      isStorage: true,
      customer: "Will G. Alice",
      amount: "€ 12.00",
      order_status: "Delivered",
      rider: "Allan Fynn",
      origin: "London, UK",
      destination: "London, UK",
      item_name: "Curtains",
    },
  ],
};
export const all_3pCustomer = {
  status: 200,
  data: [
    {
      customer_id: "1234567890",
      customer_name: "Andre Come",
      city_country: "Paris, FRA",
      phone: "40223122",
      phone_code: "+33",
      status: true,
      date_joined: "15 Jan 2023",
      total_orders: 5,
      total_amount: "€ 45.00",
      last_order: "20 Jan 2023",
      last_order_amount: "€ 10.50",
    },
    {
      customer_id: "1234567891",
      customer_name: "Ben Dykes",
      city_country: "Frankfurt, DEU",
      phone: "5655172",
      phone_code: "+49",
      status: false,
      date_joined: "25 Feb 2023",
      total_orders: 10,
      total_amount: "€ 145.00",
      last_order: "15 Apr 2023",
      last_order_amount: "€ 14.50",
    },
    {
      customer_id: "1234567892",
      customer_name: "Claude Maylen",
      city_country: "Milan, FRA",
      phone: "45665413",
      phone_code: "+39",
      status: true,
      date_joined: "01 Jan 2023",
      total_orders: 3,
      total_amount: "€ 35.00",
      last_order: "20 Jan 2023",
      last_order_amount: "€ 7.50",
    },
    {
      customer_id: "1234567893",
      customer_name: "Andre Come",
      city_country: "Paris, FRA",
      phone: "40223122",
      phone_code: "+33",
      status: true,
      date_joined: "15 Jan 2023",
      total_orders: 5,
      total_amount: "€ 45.00",
      last_order: "20 Jan 2023",
      last_order_amount: "€ 10.50",
    },
    {
      customer_id: "1234567894",
      customer_name: "Ben Dykes",
      city_country: "Frankfurt, DEU",
      phone: "5655172",
      phone_code: "+49",
      status: false,
      date_joined: "25 Feb 2023",
      total_orders: 10,
      total_amount: "€ 145.00",
      last_order: "15 Apr 2023",
      last_order_amount: "€ 14.50",
    },
    {
      customer_id: "1234567895",
      customer_name: "Claude Maylen",
      city_country: "Milan, FRA",
      phone: "45665413",
      phone_code: "+39",
      status: true,
      date_joined: "01 Jan 2023",
      total_orders: 3,
      total_amount: "€ 35.00",
      last_order: "20 Jan 2023",
      last_order_amount: "€ 7.50",
    },
    {
      customer_id: "1234567896",
      customer_name: "Andre Come",
      city_country: "Paris, FRA",
      phone: "40223122",
      phone_code: "+33",
      status: true,
      date_joined: "15 Jan 2023",
      total_orders: 5,
      total_amount: "€ 45.00",
      last_order: "20 Jan 2023",
      last_order_amount: "€ 10.50",
    },
    {
      customer_id: "1234567897",
      customer_name: "Ben Dykes",
      city_country: "Frankfurt, DEU",
      phone: "5655172",
      phone_code: "+49",
      status: false,
      date_joined: "25 Feb 2023",
      total_orders: 10,
      total_amount: "€ 145.00",
      last_order: "15 Apr 2023",
      last_order_amount: "€ 14.50",
    },
    {
      customer_id: "1234567898",
      customer_name: "Claude Maylen",
      city_country: "Milan, FRA",
      phone: "45665413",
      phone_code: "+39",
      status: true,
      date_joined: "01 Jan 2023",
      total_orders: 3,
      total_amount: "€ 35.00",
      last_order: "20 Jan 2023",
      last_order_amount: "€ 7.50",
    },
    {
      customer_id: "1234567899",
      customer_name: "Andre Come",
      city_country: "Paris, FRA",
      phone: "40223122",
      phone_code: "+33",
      status: true,
      date_joined: "15 Jan 2023",
      total_orders: 5,
      total_amount: "€ 45.00",
      last_order: "20 Jan 2023",
      last_order_amount: "€ 10.50",
    },
    {
      customer_id: "1234567900",
      customer_name: "Ben Dykes",
      city_country: "Frankfurt, DEU",
      phone: "5655172",
      phone_code: "+49",
      status: false,
      date_joined: "25 Feb 2023",
      total_orders: 10,
      total_amount: "€ 145.00",
      last_order: "15 Apr 2023",
      last_order_amount: "€ 14.50",
    },
    {
      customer_id: "1234567901",
      customer_name: "Claude Maylen",
      city_country: "Milan, FRA",
      phone: "45665413",
      phone_code: "+39",
      status: true,
      date_joined: "01 Jan 2023",
      total_orders: 3,
      total_amount: "€ 35.00",
      last_order: "20 Jan 2023",
      last_order_amount: "€ 7.50",
    },
  ],
};

export const fleet_data = {
  status: 200,
  data: [
    {
      id: "FT 54124510",
      name: "Charles Brett",
      city_country: "Paris, FRA",
      phone_code: "+33",
      phone: "140237332",
      status: true,
      date_joined: "23 Sep 2022",
      vehicles: 3,
      sub_riders: 3,
      is_also_rider: false,
      verified: true,
      fleet_manager: "Hammer Lerroy",
    },
    {
      id: "FT 54124511",
      name: "Derain Boucher",
      city_country: "Paris, FRA",
      phone_code: "+33",
      phone: "1400908722",
      status: true,
      date_joined: "16 Sep 2022",
      vehicles: 4,
      sub_riders: 6,
      is_also_rider: false,
      verified: true,
      fleet_manager: "Charles Brett",
    },
    {
      id: "FT 54124512",
      name: "Patrice Moulin",
      city_country: "Nice, FRA",
      phone_code: "+33",
      phone: "456172829",
      status: false,
      date_joined: "01 Dec 2022",
      vehicles: 5,
      sub_riders: 2,
      is_also_rider: false,
      verified: false,
      fleet_manager: "Derain Boucher",
    },
    {
      id: "FT 54124513",
      name: "Louis Gallas",
      city_country: "Bordeaux, FRA",
      phone_code: "+33",
      phone: "1405432131",
      status: true,
      date_joined: "02 Sep 2022",
      vehicles: 2,
      sub_riders: 2,
      is_also_rider: true,
      verified: true,
      fleet_manager: "Patrice Moulin",
    },
    {
      id: "FT 54124514",
      name: "Marie Lauren",
      city_country: "Paris, FRA",
      phone_code: "+33",
      phone: "140211330",
      status: true,
      date_joined: "29 Sep 2022",
      vehicles: 2,
      sub_riders: 4,
      is_also_rider: false,
      verified: true,
      fleet_manager: "Louis Gallas",
    },
    {
      id: "FT 54124515",
      name: "Charles Brett",
      city_country: "Paris, FRA",
      phone_code: "+33",
      phone: "140237332",
      status: false,
      date_joined: "23 Sep 2022",
      vehicles: 3,
      sub_riders: 3,
      is_also_rider: false,
      verified: false,
      fleet_manager: "Marie Lauren",
    },
  ],
};

export const vendors_data = {
  status: 200,
  data: [
    {
      id: "42862315",
      store_name: "Nouvelle Boutique",
      platform: "PrestaShop",
      city_country: "Paris, FRA",
      phone: "+33 4026268299",
      status: true,
      verified: true,
      date_verified: "02 Dec 2022",
      owner: "Will Rousseau",
    },
    {
      id: "42862316",
      store_name: "Des Chaussures",
      platform: "PrestaShop",
      city_country: "Paris, FRA",
      phone: "+33 4201288321",
      status: false,
      verified: true,
      date_verified: "01 Dec 2022",
      owner: "David Bernard",
    },
    {
      id: "42862317",
      store_name: "Beaux Vetements",
      platform: "Shopify",
      city_country: "Nice, FRA",
      phone: "+33 456544112",
      status: false,
      verified: false,
      date_verified: "30 Nov 2022",
      owner: "Esteve Laurent",
    },
    {
      id: "42862318",
      store_name: "Magasin Fantastique",
      platform: "PrestaShop",
      city_country: "Paris, FRA",
      phone: "+33 4565423122",
      status: true,
      verified: true,
      date_verified: "30 Nov 2022",
      owner: "Denis Petit",
    },
    {
      id: "42862319",
      store_name: "Mon Magasin",
      platform: "WooCommerce",
      city_country: "Marseille, FRA",
      phone: "+33 498765431",
      status: false,
      verified: true,
      date_verified: "28 Nov 2022",
      owner: "Hak Schmitt",
    },
  ],
};

export const allInvoices = {
  status: 200,
  data: [
    {
      id: 1234567890,
      date: "30 Dec 2022",
      type: "invoice",
      transaction_type: "Payment Received",
      entity_type: "E-merchant",
      cust_name: "Nouvelle Boutique",
      total_orders: "230",
      amount: "€ 4,250.00",
      status: "Unpaid",
    },
    {
      id: 1234567891,
      date: "29 Dec 2022",
      type: "invoice",
      transaction_type: "Payment Received",
      entity_type: "E-merchant",
      cust_name: "Salon de Maquillage",
      total_orders: "371",
      amount: "€ 5,145.00",
      status: "Unpaid",
    },
    {
      id: 1234567892,
      date: "29 Dec 2022",
      type: "invoice",
      transaction_type: "Payment Made",
      entity_type: "Rider",
      cust_name: "Chris Commoy",
      total_orders: "57",
      amount: "€ 872.75",
      status: "Partially Paid",
    },
    {
      id: 1234567893,
      date: "28 Dec 2022",
      type: "invoice",
      transaction_type: "Payment Made",
      entity_type: "Fleet Manager",
      cust_name: "Charles Brett",
      total_orders: "82",
      amount: "€ 974.00",
      status: "Unpaid",
    },
    {
      id: 1234567894,
      date: "28 Dec 2022",
      type: "invoice",
      transaction_type: "Payment Made",
      entity_type: "Space",
      cust_name: "Demander Cabine",
      total_orders: "108",
      amount: "€ 1,350.00",
      status: "Unpaid",
    },
    {
      id: 1234567895,
      date: "28 Dec 2022",
      type: "invoice",
      transaction_type: "Payment Made",
      entity_type: "Rider",
      cust_name: "Linoel Patrice",
      total_orders: "45",
      amount: "€ 620.00",
      status: "Paid",
    },
    {
      id: 1234567896,
      date: "28 Dec 2022",
      type: "invoice",
      transaction_type: "Payment Received",
      entity_type: "E-merchant",
      cust_name: "Baeux Vetements",
      total_orders: "325",
      amount: "€ 1,738.20",
      status: "Paid",
    },
  ],
};

export const allPayments = {
  status: 200,
  data: [
    {
      id: 1234567890,
      date: "30 Dec 2022",
      type: "payment",
      transaction_type: "Payment Received",
      entity_type: "E-merchant",
      cust_name: "Nouvelle Boutique",
      total_orders: "230",
      total_inv: "2 Inv.",
      mode: "Bank",
      amount: "€ 4,250.00",
    },
    {
      id: 1234567891,
      date: "29 Dec 2022",
      type: "payment",
      transaction_type: "Payment Received",
      entity_type: "E-merchant",
      cust_name: "Salon de Maquillage",
      total_orders: "371",
      total_inv: "4 Inv.",
      mode: "Bank",
      amount: "€ 5,145.00",
    },
    {
      id: 1234567892,
      date: "29 Dec 2022",
      type: "payment",
      transaction_type: "Payment Made",
      entity_type: "Rider",
      cust_name: "Chris Commoy",
      total_orders: "57",
      total_inv: "1 Inv.",
      mode: "Bank",
      amount: "€ 872.75",
    },
    {
      id: 1234567893,
      date: "28 Dec 2022",
      type: "payment",
      transaction_type: "Payment Made",
      entity_type: "Fleet Manager",
      cust_name: "Charles Brett",
      total_orders: "82",
      total_inv: "2 Inv.",
      mode: "Bank",
      amount: "€ 974.00",
    },
    {
      id: 1234567894,
      date: "28 Dec 2022",
      type: "payment",
      transaction_type: "Payment Made",
      entity_type: "Space",
      cust_name: "Demander Cabine",
      total_orders: "108",
      total_inv: "3 Inv.",
      mode: "Bank",
      amount: "€ 1,350.00",
    },
    {
      id: 1234567895,
      date: "28 Dec 2022",
      type: "payment",
      transaction_type: "Payment Made",
      entity_type: "Rider",
      cust_name: "Linoel Patrice",
      total_orders: "45",
      total_inv: "1 Inv.",
      mode: "Bank",
      amount: "€ 620.00",
    },
    {
      id: 1234567896,
      date: "28 Dec 2022",
      type: "payment",
      transaction_type: "Payment Received",
      entity_type: "E-merchant",
      cust_name: "Baeux Vetements",
      total_orders: "325",
      total_inv: "6 Inv.",
      mode: "Bank",
      amount: "€ 1,738.20",
    },
  ],
};

export const statements = {
  status: 200,
  data: [
    {
      date: "27 Dec 2022",
      transaction: "Payment Received",
      entity_type: "E-merchant",
      entity_name: "Nouvelle Boutique",
      debit: "- - -",
      credit: "€ 4,250.00",
      balance: "€ 4,250.00",
    },
    {
      date: "27 Dec 2022",
      transaction: "Payment Received",
      entity_type: "E-merchant",
      entity_name: "Salon de Maquillage",
      debit: "- - -",
      credit: "€ 5,145.50",
      balance: "€ 9,395.50",
    },
    {
      date: "23 Dec 2022",
      transaction: "Payment Made",
      entity_type: "Fleet Manager",
      entity_name: "Charles Brett",
      debit: "€ 974.00",
      credit: "- - -",
      balance: "€ 8,421.50",
    },
    {
      date: "23 Dec 2022",
      transaction: "Payment Made",
      entity_type: "Rider",
      entity_name: "Chris Commoy",
      debit: "€ 872.50",
      credit: "- - -",
      balance: "€ 7,549.50",
    },
  ],
};

export const reporting_data = {
  status: 200,
  data: [
    {
      entity_id: "RT 1234567891",
      entity_type: "Rider",
      entity_name: "Roger Smith",
      order_type: "Store Shipping",
      order_date: "11 Dec 2022",
      order_amount: "- - -",
      dest_country: "France",
      dest_city: "Paris",
    },
    {
      entity_id: "RT 1234567892",
      entity_type: "Rider",
      entity_name: "John Mills",
      order_type: "Store Shipping",
      order_date: "10 Dec 2022",
      order_amount: "- - -",
      dest_country: "France",
      dest_city: "Nice",
    },
    {
      entity_id: "RT 1234567893",
      entity_type: "Rider",
      entity_name: "Charles Brett",
      order_type: "Store Shipping",
      order_date: "07 Dec 2022",
      order_amount: "- - -",
      dest_country: "France",
      dest_city: "Lille",
    },
    {
      entity_id: "RT 1234567894",
      entity_type: "Sub-Rider",
      entity_name: "Denis Fred",
      order_type: "Store Shipping",
      order_date: "07 Dec 2022",
      order_amount: "- - -",
      dest_country: "France",
      dest_city: "Lyon",
    },
    {
      entity_id: "RT 1234567895",
      entity_type: "Sub-Rider",
      entity_name: "Robert Del Rio",
      order_type: "Store Shipping",
      order_date: "01 Dec 2022",
      order_amount: "- - -",
      dest_country: "France",
      dest_city: "Marseille",
    },
  ],
};

export const merchant_rates_data = {
  data: [
    {
      id: 1,
      sn: 1,
      product_name: "Clothes, Wear, Shoes",
      size_price: [
        { size: "Small Size", classis: 4, flex: 8, green: 10 },
        { size: "Medium Size", classis: 5, flex: 10, green: 12.5 },
        { size: "Large Size", classis: 7, flex: 14, green: 17.5 },
        { size: "Extra Large Size", classis: 15, flex: 30, green: 37.5 },
      ],
    },
    {
      id: 2,
      sn: 2,
      product_name: "Beauty, Care, Makeup",
      size_price: [
        { size: "Small Size", classis: 4, flex: 8, green: 10 },
        { size: "Medium Size", classis: 5, flex: 10, green: 12.5 },
        { size: "Large Size", classis: 7, flex: 14, green: 17.5 },
        { size: "Extra Large Size", classis: 15, flex: 30, green: 37.5 },
      ],
    },
    {
      id: 3,
      sn: 3,
      product_name: "Home Decor, Showpiece",
      size_price: [
        { size: "Small Size", classis: 4, flex: 8, green: 10 },
        { size: "Medium Size", classis: 5, flex: 10, green: 12.5 },
        { size: "Large Size", classis: 7, flex: 14, green: 17.5 },
        { size: "Extra Large Size", classis: 15, flex: 30, green: 37.5 },
      ],
    },
  ],
};

export const space_storage_rates = {
  data: [
    {
      product_name: "Clothes, Wear, Shoes",
      small: 4,
      medium: 8,
      large: 10,
      xlarge: 20,
    },
    {
      product_name: "Beauty, Care, Makeup",
      small: 5,
      medium: 10,
      large: 12,
      xlarge: 30,
    },
    {
      product_name: "Home Decor, Showpiece",
      small: 8,
      medium: 16,
      large: 32,
      xlarge: 60,
    },
  ],
};

export const rates_api_data = [
  {
    id: 1,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "4",
  },
  {
    id: 2,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "8",
  },
  {
    id: 3,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "10",
  },
  {
    id: 4,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "5",
  },
  {
    id: 5,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "10",
  },
  {
    id: 6,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "12.5",
  },
  {
    id: 7,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 8,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 9,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 10,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "15",
  },
  {
    id: 11,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "30",
  },
  {
    id: 12,
    category_level: 1,
    product_categories: [
      {
        category_name: "Cloths",
        category_level: 1,
      },
      {
        category_name: "Wear",
        category_level: 1,
      },
      {
        category_name: "Shoes",
        category_level: 1,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "37.5",
  },
  {
    id: 13,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "4",
  },
  {
    id: 14,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "8",
  },
  {
    id: 15,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "10",
  },
  {
    id: 16,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "5",
  },
  {
    id: 17,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "10",
  },
  {
    id: 18,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "12.5",
  },
  {
    id: 19,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 20,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 21,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 22,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "15",
  },
  {
    id: 23,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "30",
  },
  {
    id: 24,
    category_level: 2,
    product_categories: [
      {
        category_name: "Beauty",
        category_level: 2,
      },
      {
        category_name: "Care",
        category_level: 2,
      },
      {
        category_name: "Makeup",
        category_level: 2,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "37.5",
  },
  {
    id: 25,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "4",
  },
  {
    id: 26,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "8",
  },
  {
    id: 27,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "10",
  },
  {
    id: 28,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "5",
  },
  {
    id: 29,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "10",
  },
  {
    id: 30,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "12.5",
  },
  {
    id: 31,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 32,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 33,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 34,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "15",
  },
  {
    id: 35,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "30",
  },
  {
    id: 36,
    category_level: 3,
    product_categories: [
      {
        category_name: "Home Decor",
        category_level: 3,
      },
      {
        category_name: "Showpiece",
        category_level: 3,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "37.5",
  },
  {
    id: 37,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "5",
  },
  {
    id: 38,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "10",
  },
  {
    id: 39,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "12.5",
  },
  {
    id: 40,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 41,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 42,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 43,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 44,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 45,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 46,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "16",
  },
  {
    id: 47,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "32",
  },
  {
    id: 48,
    category_level: 4,
    product_categories: [
      {
        category_name: "Books",
        category_level: 4,
      },
      {
        category_name: "Papers",
        category_level: 4,
      },
      {
        category_name: "Stationery",
        category_level: 4,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "40",
  },
  {
    id: 49,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "5",
  },
  {
    id: 50,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "10",
  },
  {
    id: 51,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "12.5",
  },
  {
    id: 52,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 53,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 54,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 55,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 56,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 57,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 58,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "16",
  },
  {
    id: 59,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "32",
  },
  {
    id: 60,
    category_level: 5,
    product_categories: [
      {
        category_name: "Home Appliances",
        category_level: 5,
      },
      {
        category_name: "Electric",
        category_level: 5,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "40",
  },
  {
    id: 61,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "5",
  },
  {
    id: 62,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "10",
  },
  {
    id: 63,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "12.5",
  },
  {
    id: 64,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 65,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 66,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 67,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 68,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 69,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 70,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "16",
  },
  {
    id: 71,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "32",
  },
  {
    id: 72,
    category_level: 6,
    product_categories: [
      {
        category_name: "Sports",
        category_level: 6,
      },
      {
        category_name: "Gym",
        category_level: 6,
      },
      {
        category_name: "Fitness Items",
        category_level: 6,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "40",
  },
  {
    id: 73,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "5",
  },
  {
    id: 74,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "10",
  },
  {
    id: 75,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "12.5",
  },
  {
    id: 76,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 77,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 78,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 79,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 80,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 81,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 82,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "16",
  },
  {
    id: 83,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "32",
  },
  {
    id: 84,
    category_level: 7,
    product_categories: [
      {
        category_name: "Auto-parts",
        category_level: 7,
      },
      {
        category_name: "Tools",
        category_level: 7,
      },
      {
        category_name: "Metals",
        category_level: 7,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "40",
  },
  {
    id: 85,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "5",
  },
  {
    id: 86,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "10",
  },
  {
    id: 87,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "12.5",
  },
  {
    id: 88,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 89,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 90,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 91,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 92,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 93,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 94,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "16",
  },
  {
    id: 95,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "32",
  },
  {
    id: 96,
    category_level: 8,
    product_categories: [
      {
        category_name: "General Misc. Item",
        category_level: 8,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "40",
  },
  {
    id: 97,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 98,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 99,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 100,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 101,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 102,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 103,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "12",
  },
  {
    id: 104,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "24",
  },
  {
    id: 105,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "30",
  },
  {
    id: 106,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "18",
  },
  {
    id: 107,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "36",
  },
  {
    id: 108,
    category_level: 9,
    product_categories: [
      {
        category_name: "Luxury",
        category_level: 9,
      },
      {
        category_name: "Fashion",
        category_level: 9,
      },
      {
        category_name: "Perfume",
        category_level: 9,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "45",
  },
  {
    id: 109,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 110,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 111,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 112,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 113,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 114,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 115,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "12",
  },
  {
    id: 116,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "24",
  },
  {
    id: 117,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "30",
  },
  {
    id: 118,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "18",
  },
  {
    id: 119,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "36",
  },
  {
    id: 120,
    category_level: 10,
    product_categories: [
      {
        category_name: "Pet Items (w/o alive animal)",
        category_level: 10,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "45",
  },
  {
    id: 121,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 122,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 123,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 124,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 125,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 126,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 127,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "12",
  },
  {
    id: 128,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "24",
  },
  {
    id: 129,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "30",
  },
  {
    id: 130,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "18",
  },
  {
    id: 131,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "36",
  },
  {
    id: 132,
    category_level: 11,
    product_categories: [
      {
        category_name: "Baby Items",
        category_level: 11,
      },
      {
        category_name: "Kids Toys",
        category_level: 11,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "45",
  },
  {
    id: 133,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "7",
  },
  {
    id: 134,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "14",
  },
  {
    id: 135,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "17.5",
  },
  {
    id: 136,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 137,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 138,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 139,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "12",
  },
  {
    id: 140,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "24",
  },
  {
    id: 141,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "30",
  },
  {
    id: 142,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "18",
  },
  {
    id: 143,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "36",
  },
  {
    id: 144,
    category_level: 12,
    product_categories: [
      {
        category_name: "Mobile",
        category_level: 12,
      },
      {
        category_name: "Gadget",
        category_level: 12,
      },
      {
        category_name: "Electronics",
        category_level: 12,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "45",
  },
  {
    id: 145,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "8",
  },
  {
    id: 146,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "16",
  },
  {
    id: 147,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "20",
  },
  {
    id: 148,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 149,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 150,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 151,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "14",
  },
  {
    id: 152,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "28",
  },
  {
    id: 153,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "35",
  },
  {
    id: 154,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "20",
  },
  {
    id: 155,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "40",
  },
  {
    id: 156,
    category_level: 13,
    product_categories: [
      {
        category_name: "Food",
        category_level: 13,
      },
      {
        category_name: "Flower",
        category_level: 13,
      },
      {
        category_name: "Perishable",
        category_level: 13,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "50",
  },
  {
    id: 157,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "8",
  },
  {
    id: 158,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "16",
  },
  {
    id: 159,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "20",
  },
  {
    id: 160,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 161,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 162,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 163,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "14",
  },
  {
    id: 164,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "28",
  },
  {
    id: 165,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "35",
  },
  {
    id: 166,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "20",
  },
  {
    id: 167,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "40",
  },
  {
    id: 168,
    category_level: 14,
    product_categories: [
      {
        category_name: "Furniture Items",
        category_level: 14,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "50",
  },
  {
    id: 169,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Classic",
    rate: "8",
  },
  {
    id: 170,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Flex",
    rate: "16",
  },
  {
    id: 171,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Small",
    delivery_type: "Green",
    rate: "20",
  },
  {
    id: 172,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Classic",
    rate: "10",
  },
  {
    id: 173,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Flex",
    rate: "20",
  },
  {
    id: 174,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Medium",
    delivery_type: "Green",
    rate: "25",
  },
  {
    id: 175,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Classic",
    rate: "14",
  },
  {
    id: 176,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Flex",
    rate: "28",
  },
  {
    id: 177,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Large",
    delivery_type: "Green",
    rate: "35",
  },
  {
    id: 178,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Classic",
    rate: "20",
  },
  {
    id: 179,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Flex",
    rate: "40",
  },
  {
    id: 180,
    category_level: 15,
    product_categories: [
      {
        category_name: "Odd size Pipe",
        category_level: 15,
      },
      {
        category_name: "Rod",
        category_level: 15,
      },
    ],
    parcel_size: "Extra Large",
    delivery_type: "Green",
    rate: "50",
  },
];

export const commissions = {
  status: 201,
  data: [
    {
      id:'COM-2023-02-01-0001',
      name: "Default Fleetmanager",
      is_default: true,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Fleetmanager",
      is_active: true,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
    },
    {
      id:'COM-2023-02-01-0002',
      name: "Default Rider",
      is_default: true,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Rider",
      is_active: true,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
      updated_by: "Ahmed Khan",
      updated_at: "2023-05-15",
    },
    {
      id:'COM-2023-02-01-0003',
      name: "Default E-Merchant",
      is_default: true,
      basic_1: 44.22,
      basic_2: 90.94,
      com_type: "E-Merchant & D2C Store",
      is_active: false,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Sheeraz Anwar",
      created_at: "2023-01-01",
    },
    {
      id:'COM-2023-02-01-0004',
      name: "Default Space",
      is_default: true,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Space",
      is_active: true,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
    },
    {
      id:'COM-2023-02-01-0005',
      name: "Space Customised 1",
      is_default: false,
      basic_1: 22.22,
      basic_2: 13.94,
      com_type: "Space",
      is_active: true,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
    },
    {
      id:'COM-2023-02-01-0006',
      name: "Applicable Date",
      is_default: false,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Fleetmanager",
      is_active: true,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
    },
    {
      id:'COM-2023-02-01-0007',
      name: "End Date",
      is_default: false,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Fleetmanager",
      is_active: false,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
    },
    {
      id:'COM-2023-02-01-0008',
      name: "Rider Custom",
      is_default: false,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Rider",
      is_active: true,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
      updated_by: "Ahmed Khan",
      updated_at: "2023-05-15",
    },
    {
      id:'COM-2023-02-01-0009',
      name: "Space -2",
      is_default: false,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Space",
      is_active: true,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
    },
    {
      id:'COM-2023-02-01-00010',
      name: "Customise E-Merchant",
      is_default: false,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "E-Merchant & D2C Store",
      is_active: false,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
      updated_by: "Ahmed Khan",
      updated_at: "2023-05-15",
    },
    {
      id:'COM-2023-02-01-00011',
      name: "Space - 3",
      is_default: false,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Space",
      is_active: false,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
    },
    {
      id:'COM-2023-02-01-00012',
      name: "Rider- 3",
      is_default: false,
      basic_1: 24.22,
      basic_2: 18.94,
      com_type: "Rider",
      is_active: false,
      start_date: "2023-01-01",
      end_date: "2023-09-30",
      created_by: "Muhammad Hassaan",
      created_at: "2023-01-01",
      updated_by: "Ahmed Khan",
      updated_at: "2023-05-15",
    },
  ],
};
