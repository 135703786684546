import React from "react";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import PropTypes from "prop-types";

interface OrderState {
  classes: any;
  style: any;
}

export default function Check(props: OrderState) {
  const { classes, style } = props;
  return <CheckBoxOutlinedIcon style={style} className={classes} />;
}

Check.defaultProps = {
  classes: {},
  style: {},
};
Check.propTypes = {
  classes: PropTypes.string,
  style: PropTypes.object,
};
