import React from "react";
import { BulkImage, Login } from "./PageAsync";

export default function AuthenticationRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    children: [
      {
        path: "/",
        element: <Login classes={classes} />,
      },
      {
        path: "/reset/:token",
        element: <Login classes={classes} />,
      },
      {
        path: "/*",
        element: <Login classes={classes} />,
      },
      {
        path: "/bulk-images",
        element: <BulkImage classes={classes} />,
      },
    ],
  };
  return MainRoutes;
}
