import {
  ContentCopyOutlined,
  ExpandMore,
  ImageOutlined,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  InputAdornment,
} from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine-sc";
import classNames from "classnames";
import moment from "moment";
import { title } from "process";
import React from "react";
import InputField from "ui-components/TextFeild";
import ViewImageModal from "ui-components/ViewImageModal";
import { getImageNameFromURL } from "utils/helper";

export default function Profile(props: any) {
  const { classes, panel_head, from_vendors, details } = props;
  // const dispatch = useDispatch<any>();
  // const miniShop = useSelector((state: RootState) => state.vendors);
  // const vendorSlice = useSelector((state: RootState) => state.vendors);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      show_image_modal: false,
    }
  );

  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        defaultExpanded
        // expanded={
        //   from_vendors
        //     ? vendorSlice.panels.status
        //     : miniShop.panels.status
        // }
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
              // onClick={() =>
              //   dispatch(
              //     dispatch(
              //       from_vendors
              //         ? toggleStatus(!vendorSlice.panels.status as any)
              //         : handleChangeStatus(!miniShop.panels.status as any)
              //     )
              //   )
              // }
            />
          }
        >
          <span
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1 }}
          >
            {panel_head}
          </span>
          {/* <ContentCopyOutlined
            style={{ marginRight: 10 }}
            className={classNames(classes.accordion_icons)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          />
          <PictureAsPdfOutlined
            style={{ marginRight: 10 }}
            className={classNames(classes.accordion_icons)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          /> */}
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          <Grid container spacing={4}>
          <Grid xs={12} md={6} item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "",
                  alignItems: "",
                  flexDirection: "column",
                }}
              >
                <div>
                  <p
                    style={{
                      color: "#666666",
                      fontFamily: "RobotoCondensed-Regular",
                      paddingLeft: 10,
                      fontSize: "17px",
                    }}
                  >
                    Shop Image
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    borderRadius: 4,
                  }}
                >
                  <img
                    src={
                      details?.image
                        ? details?.image
                        : "https://media.istockphoto.com/id/1131164548/vector/avatar-5.jpg?s=612x612&w=0&k=20&c=CK49ShLJwDxE4kiroCR42kimTuuhvuo2FH5y_6aSgEo="
                    }
                    height={100}
                    width={100}
                    style={{objectFit:"contain"}}
                    
                  ></img>
                </div>
              </div>
            </Grid>
            <Grid xs={12} md={6} item>
              {/* <InputField
                fullWidth
                value={details?.id ?? "- - -"}
                input_label={"ID"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              /> */}
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.shop_name ?? `${details?.first_name}'shop`}
                input_label={"Shop Name"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
          
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.type_name ?? "- - -"}
                input_label={"Shop Type"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>

           
           
            {/* desx */}
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={
                  `${details?.phone_code ?? "- -"} ${
                    details?.phone ?? "- -"
                  }` ?? "- - -"
                }
                input_label={"Phone"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                input_label={"Email"}
                value={details?.email ?? "- - -"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={ `${details?.first_name ?? "- -"} ${details?.last_name ?? "- -"}` ?? "- - -"}              
                input_label={"Shop Owner name"}
                variant={"outlined"}
                
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                  title: details?.shop_desc,
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.id ?? "- - -"}
                input_label={"ID"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
            <Grid xs={12} md={6} item>
              <InputField
                fullWidth
                value={details?.created_at? moment(details.created_at).format("DD/MM/YYYY HH:mm"):"- -"}
                input_label={"Created at"}
                variant={"outlined"}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
                classes={classes}
              />
            </Grid>
           
             {
              details?.type_name!=="Particular" &&(
                <Grid xs={12} md={12} item>
                <InputField
                  fullWidth
                  value={details?.shop_desc ?? "- - -"}
                  input_label={"Description"}
                  variant={"outlined"}
                  multiline
                  rows={details?.shop_desc?.length<30?2:4}
                  InputProps={{
                    readOnly: true,
                  }}
                  onChange={(e: any) => {}}
                  inputProps={{
                    className: classNames(classes?.input_feild, "Roboto-Medium"),
                    title: details?.shop_desc,
                  }}
                  classes={classes}
                />
              </Grid>
              )
             }   
           
           
           
          </Grid>
        </AccordionDetails>
      </Accordion>
      <ViewImageModal
        activity={state.show_image_modal}
        handleCloseModal={() => setState({ show_image_modal: false })}
        image_source={details?.image ?? ""}
      />
    </StyledEngineProvider>
  );
}
