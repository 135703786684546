import { styled } from "@mui/material/styles";
import { FormControlLabel, Switch, SwitchProps } from "@mui/material";
import React from "react";
import { defaultLabelColors } from "utils/constants";
import classNames from "classnames";

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#8fa6ff" : "#8fa6ff",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: defaultLabelColors.color_f,
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

interface SwitchFeildProps {
  input_label: string;
  required?: boolean;
  check?: boolean;
  onChange?: (event: any) => void;
  disabled?: boolean;
  classes?: any;
  name?: string;
}

export default function SwitchFeild(props: SwitchFeildProps) {
  const { check, input_label, required, onChange, disabled, classes, name } =
    props;
  return (
    <div>
      {input_label && (
        <label
          className={classNames(
            classes.input_label_style,
            "RobotoCondensed-Regular"
          )}
          style={{ marginRight: 15 }}
        >
          {input_label}
        </label>
      )}

      <FormControlLabel
        checked={check}
        label=""
        name={name}
        control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
}
