import {
    ContentCopyOutlined,
    ExpandMore,
    PictureAsPdfOutlined,
  } from "@mui/icons-material";
  import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    StyledEngineProvider
  } from "@mui/material";
  import classNames from "classnames";
  import InputField from "ui-components/TextFeild";
  import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { defaultLabelColors } from "utils/constants";

  export default function ServiceMethod(props: any) {
    const { classes, panel_head, from_vendors, details } = props;
    // const dispatch = useDispatch<any>();
    // const miniShop = useSelector((state: RootState) => state.transporterSlice);
    // const vendorSlice = useSelector((state: RootState) => state.vendors);
    // React.useEffect()
    return (
      <StyledEngineProvider injectFirst>
        <Accordion
        defaultExpanded
          // expanded={
          //   from_vendors ? vendorSlice.panels.status : miniShop.panels.status
          // }
          className={classNames(classes.accordion_container)}
        >
          <AccordionSummary
            className={classNames(classes.accordion_head_container)}
            expandIcon={
              <ExpandMore
                className={classes.accordion_icons}
                style={{ fontSize: 24 }}
                // onClick={() =>
                //   dispatch(
                //     from_vendors
                //       ? toggleStatus(!vendorSlice.panels.status as any)
                //       : handleChangeStatus(!miniShop.panels.status as any)
                //   )
                // }
              />
            }
          >
            <span
              // onClick={() =>
              //   dispatch(
              //     from_vendors
              //       ? toggleStatus(!vendorSlice.panels.status as any)
              //       : handleChangeStatus(!miniShop.panels.status as any)
              //   )
              // }
              className={classNames(classes.accordion_heading)}
              style={{ flex: 1,}}
            >
              {panel_head}
            </span>
            {/* <ContentCopyOutlined
              style={{ marginRight: 10 }}
              className={classNames(classes.accordion_icons)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <PictureAsPdfOutlined
              style={{ marginRight: 10 }}
              className={classNames(classes.accordion_icons)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            /> */}
          </AccordionSummary>
  
          <AccordionDetails className={classes.accordion_detail_padding}>
          <TableContainer>
            <Table
              style={{
                minWidth: "max-content",
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow className={classes?.background_light_gray}>
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                      width: 120,
                    }}
                  >
                    Servive Method
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                      width: 120,
                    }}
                  >
                    {/* Price */}
                    Available
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                      width: 120,
                    }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                //   style={{
                //     background: index % 2 ? "#f6f6f6" : "#fff",
                //     borderBottom:
                //       index === row.state?.result.length - 1
                //         ? "1px solid #c5c5c5"
                //         : "",
                //   }}
                >
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    }}
                  >
                    {"Click and Collect"}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    color:  details?.service_method?.click_and_collect?.owner
                        ? defaultLabelColors?.green
                        : defaultLabelColors?.red
                    }}
                  >
                    {details?.service_method?.click_and_collect?.owner
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
                <TableRow
                //   style={{
                //     background: index % 2 ? "#f6f6f6" : "#fff",
                //     borderBottom:
                //       index === row.state?.result.length - 1
                //         ? "1px solid #c5c5c5"
                //         : "",
                //   }}
                >
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    
                    }}
                  >
                    {"Relais"}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    color:  details?.service_method?.relais?.owner
                        ? defaultLabelColors?.green
                        : defaultLabelColors?.red
                    
                    }}
                  >
                    {details?.service_method?.relais?.owner ? "Yes" : "No"}
                  </TableCell>
                </TableRow>{" "}
                <TableRow
                //   style={{
                //     background: index % 2 ? "#f6f6f6" : "#fff",
                //     borderBottom:
                //       index === row.state?.result.length - 1
                //         ? "1px solid #c5c5c5"
                //         : "",
                //   }}
                >
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                      width: 120,
                    }}
                  >
                    {"Express"}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    color:  details?.service_method?.express?.owner
                        ? defaultLabelColors?.green
                        : defaultLabelColors?.red
                    }}
                  >
                    {details?.service_method?.express?.owner ? "Yes" : "No"}
                  </TableCell>
                </TableRow>{" "}
                <TableRow
                //   style={{
                //     background: index % 2 ? "#f6f6f6" : "#fff",
                //     borderBottom:
                //       index === row.state?.result.length - 1
                //         ? "1px solid #c5c5c5"
                //         : "",
                //   }}
                >
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    }}
                  >
                    {"Regular"}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    color:  details?.service_method?.regular?.owner
                    ? defaultLabelColors?.green
                    : defaultLabelColors?.red
                    }}

                  >
                    {details?.service_method?.regular?.owner ? "Yes" : "No"}
                  </TableCell>
                </TableRow>
                <TableRow
                //   style={{
                //     background: index % 2 ? "#f6f6f6" : "#fff",
                //     borderBottom:
                //       index === row.state?.result.length - 1
                //         ? "1px solid #c5c5c5"
                //         : "",
                //   }}
                >
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    }}
                  >
                    {"Self Delivery"}
                  </TableCell>
                  <TableCell
                    className={classNames(
                      classes?.table_head,
                      "padding-10",
                      "RobotoCondensed-Medium"
                    )}
                    style={{
                    //   width: 120,
                    color:  details?.service_method?.self_delivery?.owner
                    ? defaultLabelColors?.green
                    : defaultLabelColors?.red
                    }}
                  >
                    {details?.service_method?.self_delivery?.owner
                      ? "Yes"
                      : "No"}
                  </TableCell>
                </TableRow>
                {/* {Object.entries(details?.service_method).map(([method, permissions], index) => (
            <TableRow
              key={method}
              // style={{
              //   background: index % 2 ? "#f6f6f6" : "#fff",
              //   borderBottom: index === Object.keys(serviceMethods).length - 1 ? "1px solid #c5c5c5" : "",
              // }}
            >
              <TableCell
                className={classNames(
                  classes?.table_head,
                  "padding-10",
                  "RobotoCondensed-Medium"
                )}
                style={{ width: 120 }}
              >
                {method.replace(/_/g, ' ')}
              </TableCell>
              <TableCell
                className={classNames(
                  classes?.table_head,
                  "padding-10",
                  "RobotoCondensed-Medium"
                )}
                style={{ width: 120 }}
              >
                {details?.service_method?.[method]?.owner ? "Yes" : "No"}
              </TableCell>
            </TableRow>
          ))} */}
              </TableBody>
            </Table>
          </TableContainer>
          </AccordionDetails>
        </Accordion>
      </StyledEngineProvider>
    );
  }
  