import { useRoutes } from "react-router-dom";
import { MainAppRoutes } from "./MainAppRoutes";
import useStyles from "./style-jss";
import AuthenticationRoutes from "./AuthenticationRoutes";
import { getDataFromLocalStorage } from "utils/LocalStore.util";
import { useDispatch } from "react-redux";
import { getDeliveryTypes } from "store/orderReducers";

// routes
// import MainRoutes from "./MainAppRoutes";

export function AppRoute() {
  const classes = useStyles() as any;
  const dispatch = useDispatch<any>();
  
  let user = getDataFromLocalStorage("user");

  let pvt_routes = MainAppRoutes({ classes, user });

  let auth_element = AuthenticationRoutes({ classes });


  dispatch(getDeliveryTypes({ end_point: "parents/get-delivery-types" }));

  // eslint-disable-next-line
  if (!user) return useRoutes([auth_element]);

  // eslint-disable-next-line
  return useRoutes([pvt_routes]);
}

export default AppRoute;
