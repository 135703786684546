import { CloudUpload } from "@mui/icons-material";
import {
  DialogActions,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from "@mui/material";
import axios from "axios";
import classNames from "classnames";
import React, { useEffect } from "react";
import { BASE_URL } from "services/HttpProvider";
import apiServices from "services/RequestHandler";
import Button from "ui-components/Button";
import SelectField from "ui-components/SelectField";
import InputField from "ui-components/TextFeild";
import Toast from "ui-components/Toast";
import { defaultLabelColors } from "utils/constants";

export default function ProductDetail(props: any) {
  const { classes, id } = props;
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      temp_img_url: "",
      result: {
        product_title: "",
        product_desc: "",
        price: "",
        images: "",
        color: "",
        cat_name: "",
        is_used: null,
        used_type: "",
        prod_size: "",
        prod_brand: "",
        category_id: "",
      },
    }
  );
  useEffect(() => {
    getCategory();
  }, []);
  useEffect(() => {
    getShopProduct();
  }, [id]);
  const getShopProduct = async () => {
    try {
      if (id) {
        const resp = await apiServices.getFromApi(`/c2c-products/${id}`, {});
        if (resp?.status === 200) {
          setState({ result: resp?.data, temp_img_url: resp?.data?.images[0] });
        } else {
          console.log("error");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  //
  const getCategory = async () => {
    try {
      const resp = await apiServices.getFromApi(`c2c-categories`, {});
      if (resp?.status === 200) {
        let cattt = resp.data.map((category: any) => ({
          label: category.category_name.en,
          value: category.id,
        }));
        setState({ category: cattt });
      } else {
        console.log("error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  /// select field
  const handleOrderTypeSelect = (
    name: string,
    event: any,
    values: any
    // index: any
  ) => {
    try {
      if (!values) {
        setState({
          [name]: null,
          [`selected_${name}`]: null,
        });
        return;
      }
      let { result } = state;
      let new_obj = {
        ...result,
        [name]: values.value,
      };
      ///error when same value

      result = new_obj;
      //   console.log(amount);
      setState({
        result,
      });
      setState({
        [name]: values.value,
        [`selected_${name}`]: values,
      });
    } catch (error) {
      console.log(error);
    }
  };
  // input change
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    try {
      const { name, value } = event.target;
      let { result } = state;
  
      // Define the regex for numbers and decimal numbers
      const priceRegex =/^[0-9]*\.?[0-9]*$/;
  
      // Validate the price field if name is 'price'
      if (name === 'price' && !priceRegex.test(value)) {
        console.error('Invalid price format');
        return; // Exit the function if validation fails
      }
  
      // Create new object with updated field
      let new_obj = {
        ...result,
        [name]: value,
      };
  
      // Update result and state
      result = new_obj;
      setState({
        result,
      });
    } catch (error) {
      console.error('Error handling input change:', error);
    }
  };
  
  ///image change
  const handleImageChangeWeb = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files || event.target.files.length === 0) {
      return; // No file selected, do nothing
    }
    const { name } = event.target;

    const file = event.target.files[0];
    const fileSize = file.size;

    const image = new Image();
    image.src = URL.createObjectURL(file);
    // let { result } = state;

    // let new_obj = {
    //   ...result,
    //   ["images"]: [URL.createObjectURL(file)],
    // };
    // result = new_obj;

    setState({
      // result,
      image_file_web: file,
      temp_img_url: URL.createObjectURL(file),
    });
  };
  // uplaodto s3l
  const UploadtoS3 = async () => {
    try {
      let formData = new FormData();
      let timestamp = new Date().getTime();
      let fileName1 = `image_product_${timestamp}`;

      let myfile = new File([state.image_file_web], fileName1, {
        type: state.image_file_web.type,
      });

      formData.append("files", myfile);
      const resp = await axios({
        baseURL: BASE_URL,
        url: "/upload/upload-multiple",
        headers: {
          "Content-Type": "multipart/form-data", // this is important
        },
        method: "post",
        data: formData,
      });

      if (resp.data.status === 201) {
        // handleSendBanner(resp?.data?.data[0]?.uri, resp?.data?.data[1]?.uri);
        //  setState({ imageLink: resp?.data?.data[0]?.uri })
        let { result } = state;
        // let new_obj = {
        //   ...result,
        //   ["images"]: [resp?.data?.data[0]?.uri],
        // };
        // result = new_obj;

        // setState({
        //   result,

        // });
        console.log(resp?.data?.data[0]?.uri);

        return resp?.data?.data[0]?.uri ?? null; // Return the URI or null
      } else {
        Toast.showError(
          "Unable to create banner, please try again. If the problem persists, contact technical support."
        );
      }
    } catch (error) {
      console.error("Error uploading image or creating banner:", error);
      Toast.showError("Something went wrong while processing your request.");
    }
  };

  /// create
  const handleUpload = async () => {
    const url = await UploadtoS3();
    if (url) {
      console.log(url,"---")
      setState({ url: url });
    }
  };

  function extractPropertiesForUpdate(obj: any) {
    return {
      id: Number(obj.id),
      shop_id: Number(obj.shop_id),
      owner_id: Number(obj.owner_id),
      modified_by: obj.added_by,
      category_id: Number(obj.category_id),
      added_by: obj.added_by,
      product_title: obj.product_title,
      product_desc: obj.product_desc,
      price: Number(obj.price),
      // discount: Number(obj.discount),
      prod_brand: obj?.prod_brand,
      prod_size: obj.prod_size,
      images: [state?.url ?? obj.images[0]], // assuming `images` is an array of strings
      color: obj.color,
      is_used: obj.is_used, // if the boolean values are strings
      used_type: obj?.used_type,

      // is_new: obj.is_new === 'true' // if the boolean values are strings
    };
  }

  const UpdateProduct = async () => {
    // const updatedObj=extractPropertiesForUpdate(state.result)
    if (!state.result.product_title || !state.result.product_title === null) {
      Toast.showError("Please fill Title");
      return;
    }

    if (!state?.result?.prod_size) {
      Toast.showError("Please Select Size");
      return;
    }
    // if (!state?.result?.cat_name) {
    //   Toast.showError("Please Select Category");
    //   return;
    // }
    if (!state?.result?.price) {
      Toast.showError("Please Select price");
      return;
    }
    if (!state?.result?.used_type) {
      Toast.showError("Please Select Used Type");
      return;
    }

    // if (state?.result.length>0&&state?.result.height>0&&state?.result.width>0) {
    //   Toast.showError("");
    //   return;
    // }
    try {
      if (state?.image_file_web) {
        handleUpload();
      }

      const createdObj = extractPropertiesForUpdate(state.result);

      const response = await apiServices.patchFromApi(
        "/c2c-products/admin",
        createdObj,
        ""
      );

      if (response.status === 201) {
        Toast.showSuccess("Product Updated successfully.");
        // get_all_charges();
        // getSingleItem();
        // navigate("/management/items-list")
        props?.handleClose()
      } else {
        Toast.showError(
          response?.data?.message ||
            "An error occurred while Updating the Item."
        );
      }
      console.log(createdObj, "objh");
    } catch (error) {
      console.error("Error while Updating :", error);
      Toast.showError("An unexpected error occurred. Please try again later.");
    }
  };

  //create//
  function extractPropertiesForCreate(obj: any,url?:any) {

    return {
      // id: Number(obj.id),
      shop_id: Number(props?.shop_detail?.id),
      owner_id: Number(props?.shop_detail?.owner_id),
      // modified_by: obj.added_by,
      category_id: Number(obj.category_id),
      added_by: props?.shop_detail?.added_by,
      product_title: obj.product_title,
      product_desc: obj.product_desc,
      price: Number(obj.price),
      // discount: Number(obj.discount),
      prod_brand: obj?.prod_brand,
      prod_size: obj.prod_size,
      images: [url], // assuming `images` is an array of strings
      color: obj.color,
      is_used: true, // if the boolean values are strings
      used_type: obj?.used_type,

      // is_new: obj.is_new === 'true' // if the boolean values are strings
    };
  }

  const AddProduct = async() => {
   // const updatedObj=extractPropertiesForUpdate(state.result)
   if (!state.result.product_title || !state.result.product_title === null) {
    Toast.showError("Please fill Title");
    return;
  }

  if (!state?.result?.prod_size) {
    Toast.showError("Please Select Size");
    return;
  }
  // if (!state?.result?.cat_name) {
  //   Toast.showError("Please Select Category");
  //   return;
  // }
  if (!state?.result?.price) {
    Toast.showError("Please Select price");
    return;
  }
  if(props?.shop_detail?.type_name==="Particular"){
    if (!state?.result?.used_type) {
      Toast.showError("Please Select Used Type");
      return;
  }
  
  } if (!state?.image_file_web) {
    Toast.showError("Please Select Image ");
    return;
  }

  // if (state?.result.length>0&&state?.result.height>0&&state?.result.width>0) {
  //   Toast.showError("");
  //   return;
  // }
  try {
    const imageUrl = await UploadtoS3();

    const createdObj = extractPropertiesForCreate(state.result,imageUrl);

    const response = await apiServices.postFromApi(
      "/c2c-products/admin",
      createdObj,
      ""
    );

    if (response.status === 201) {
      Toast.showSuccess("Product Updated successfully.");
      // get_all_charges();
      // getSingleItem();
      // navigate("/management/items-list")
      props?.handleClose()
    } else {
      Toast.showError(
        response?.data?.message ||
          "An error occurred while Updating the Item."
      );
    }
    console.log(createdObj, "objh");
  } catch (error) {
    console.error("Error while Updating :", error);
    Toast.showError("An unexpected error occurred. Please try again later.");
  }
  };
  ////delete
  const DeleteProduct=async()=>{
    try {
     
      const response = await apiServices.patchFromApi(
        `/c2c-products/admin/${id}`,{
        
            shop_id: Number(props?.shop_detail?.id),
            owner_id:  Number(props?.shop_detail?.owner_id),
            modified_by: props?.shop_detail?.added_by,
          
        },
        ""
      );
  
      if (response.status === 201) {
        Toast.showSuccess("Product Updated successfully.");
        // get_all_charges();
        // getSingleItem();
        // navigate("/management/items-list")
        props?.handleClose()
      } else {
        Toast.showError(
          response?.data?.message ||
            "An error occurred while Delete the Product."
        );
      }
    } catch (error) {
      console.error("Error while Deleting :", error);
      Toast.showError("An unexpected error occurred. Please try again later.");
    }
  }
  return (
    <div className={classes.main_layout_padding}>
      {id ? (
        <div className={classes.banner_info_header}>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              paddingLeft: 10,
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              fontSize={"1.3rem"}
              fontFamily={"Roboto-Medium"}
            >
              {/* Item Detail */}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Roboto-Medium",
            }}
          >
            {id && (
              <FormControlLabel
                control={
                  <Switch
                  // checked={state?.is_edit}
                  // onChange={handleSwitchChange}
                  />
                }
                label="Edit"
                //   sx={{fontFamily:"Roboto-Medium",fontSize:"1.2rem"}}
              />
            )}
          </div>
        </div>
      ) : null}

      <Grid item container spacing={2}>
        <Grid xs={12} sm={6} item>
          <div>
            <InputField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              value={state?.result?.product_title}
              name={"product_title"}
              input_label="Title"
              required
              onChange={handleInputChange}
              inputProps={{
                className: classNames(classes?.input_feild, "Roboto-Medium"),
              }}
            />
          </div>
        </Grid>
        <Grid xs={12} sm={6} item>
          <div>
            <InputField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              //   value={state.sub_title}
              value={state?.result?.prod_brand ?? "-"}
              name={"prod_brand"}
              input_label="Brand"
              // required
              onChange={handleInputChange}
              inputProps={{
                className: classNames(classes?.input_feild, "Roboto-Medium"),
              }}
            />
          </div>
        </Grid>
        <Grid xs={12} sm={6} item>
          <div>
            {/* <InputField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              //   value={state.sub_title}
              value={state?.result?.cat_name}
              name={"cat_name"}
              input_label="Category"
              required
              onChange={handleInputChange}
              inputProps={{
                className: classNames(classes?.input_feild, "Roboto-Medium"),
              }}
            /> */}
            <SelectField
              options={state?.category}
              value={{
                label: state?.result?.cat_name,
                value: state?.result?.category_id,
              }}
              name={"category_id"}
              input_label={"Category"}
              placeholder={""}
              required
              handleSelect={(event: any, name: any, value: any) =>
                handleOrderTypeSelect(
                  event,
                  name,
                  value
                  // index
                )
              }
              classes={classes}
              // disableClearable={state?.is_edit}
              // readOnly={!state?.is_edit}
            />{" "}
          </div>
        </Grid>
        <Grid xs={12} sm={6} item>
          <div>
            {/* <InputField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              //   value={state.sub_title}
              value={state?.result?.prod_size}
              name={"sub_title"}
              input_label="Size"
              // required
              //   onChange={handleInputChange}
              inputProps={{
                className: classNames(classes?.input_feild, "Roboto-Medium"),
              }}
            /> */}
            <SelectField
              options={[
                { label: "Small ●", value: "Small" },
                { label: "Medium ●", value: "Medium" },
                { label: "Large ●", value: "Large" },
                { label: "Extra Large ●", value: "Extra Large" },
              ]}
              value={{
                label: state?.result?.prod_size,
                value: state?.result?.prod_size,
              }}
              name={"prod_size"}
              input_label={"Size"}
              placeholder={""}
              required
              handleSelect={(event: any, name: any, value: any) =>
                handleOrderTypeSelect(
                  event,
                  name,
                  value
                  // index
                )
              }
              classes={classes}
              // disableClearable={state?.is_edit}
              // readOnly={!state?.is_edit}
            />{" "}
          </div>
        </Grid>
        <Grid xs={12} sm={4} item>
          <div>
            <InputField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              //   value={state.sub_title}
              value={state?.result?.price}
              name={"price"}
              input_label="Price"
              required
                onChange={handleInputChange}
              inputProps={{
                className: classNames(classes?.input_feild, "Roboto-Medium"),
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">{"€"}</InputAdornment>
                ),
              }}
            />
          </div>
        </Grid>

        <Grid xs={12} sm={4} item>
          <div>
            {/* <InputField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              //   value={state.sub_title}
              value={state?.result?.color}
              name={"sub_title"}
              input_label="Color"
              // required
              //   onChange={handleInputChange}
              inputProps={{
                className: classNames(classes?.input_feild, "Roboto-Medium"),
              }}
            /> */}
            <SelectField
              options={[
                { label: "Black ", value: "Black" },
                { label: "White", value: "White" },
                { label: "Navy", value: "Navy" },
                { label: "Blue", value: "Blue" },
                { label: "Gray", value: "Gray" },
                { label: "Silver", value: "Silver" },
                { label: "Orange", value: "Orange" },
                { label: "Yellow", value: "Yellow" },
                { label: "Brown", value: "Brown" },
                { label: "Red", value: "Red" },
                { label: "Purple", value: "Purple" },
                { label: "Pink", value: "Pink" },
                { label: "Green", value: "Green" },
                { label: "Lime", value: "Lime" },
              ]}
              value={{
                label: state?.result?.color,
                value: state?.result?.color,
              }}
              style={{
                color: "red",
              }}
              name={"color"}
              input_label={"Color"}
              placeholder={""}
              // required
              handleSelect={(event: any, name: any, value: any) =>
                handleOrderTypeSelect(
                  event,
                  name,
                  value
                  // index
                )
              }
              classes={classes}
              // disableClearable={state?.is_edit}
              // readOnly={!state?.is_edit}
            />{" "}
          </div>
        </Grid>
        {props?.shop_detail?.type_name==="Particular" && (
          <Grid xs={12} sm={4} item>
            <div>
              {/* <InputField
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                //   value={state.sub_title}
                value={state?.result?.used_type}
                name={"used_type"}
                input_label="Use Type"
                // required
                //   onChange={handleInputChange}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                }}
              /> */}
              <SelectField
                options={[
                  { label: "Almost New ", value: "Almost New" },
                  { label: "Fair ", value: "Fair" },
                  { label: "Good ", value: "Good" },
                  { label: "Poor ", value: "Poor" },
                ]}
                value={{
                  label: state?.result?.used_type,
                  value: state?.result?.used_type,
                }}
                name={"used_type"}
                input_label={"Used Type"}
                placeholder={""}
                required
                handleSelect={(event: any, name: any, value: any) =>
                  handleOrderTypeSelect(
                    event,
                    name,
                    value
                    // index
                  )
                }
                classes={classes}
                // disableClearable={state?.is_edit}
                // readOnly={!state?.is_edit}
              />{" "}
            </div>
          </Grid>
        )}

        <Grid xs={12} sm={6} item>
          <div>
            <InputField
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              //   value={state.desc}
              value={state?.result?.product_desc}
              name={"product_desc"}
              input_label="Description "
              // required
                onChange={handleInputChange}
              multiline={true}
              inputProps={{
                className: classNames(classes?.input_feild, "Roboto-Medium"),
              }}
              rows={10}
            />
          </div>
        </Grid>
        <Grid xs={12} sm={6} item>
          <label
            className={classNames(
              classes.input_label_style,
              "RobotoCondensed-Regular"
            )}
            style={{ display: "inline-flex" }}
          >
            Product Image
            <br />
            <span
              className={classNames(
                classes.input_label_style,
                "RobotoCondensed-Regular"
              )}
            >
              {" "}
            </span>
          </label>
          <div
            style={{
              height: "22.5vh",
              width: "100%",
              borderRadius: 6,
              // position: "relative",
            }}
          >
            <div
              style={{
                // position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                flexDirection: "column",
                borderRadius: 6,
                border: "1px solid #0000003b",
                borderStyle: "solid",
                backgroundColor: "#fff",
                position: "relative",
              }}
            >
              {state?.temp_img_url ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    height: "100%",
                    justifyContent: "center",
                    padding: 10,
                  }}
                >
                  <img
                    src={state?.temp_img_url}
                    style={{
                      marginBottom: 0,
                      height: "83%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                    alt=""
                  />
                </div>
              ) : (
                <>
                  <CloudUpload
                    style={{
                      color: defaultLabelColors.main_blue,
                      fontSize: "4rem",
                    }}
                  />
                  <p style={{ fontSize: 14 }}>Drag and drop your image here </p>
                  <h4>OR</h4>
                </>
              )}
              <div
                style={{
                  padding: "5px 15px",
                  background: "white",
                  border: `1px solid ${defaultLabelColors.main_blue}`,
                  borderRadius: 6,
                  fontSize: 14,
                  fontFamily: "Roboto-Medium",
                  color: "#3026f1",
                  position: "absolute",
                  bottom: 10,
                }}
              >
                <label htmlFor="file">Select Image</label>
              </div>
              <input
                style={{
                  visibility: "hidden",
                }}
                type="file"
                name="files[]"
                accept="image/*"
                id="file"
                onChange={handleImageChangeWeb}
              />
            </div>
          </div>
        </Grid>
        <Grid xs={12} sm={12} item>
          <DialogActions>
            <div style={{ flex: 1 }}>
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.outlined_button
                )}
                // disable={id ? false : true}
                value={"Delete"}
                onClick={DeleteProduct}
                style={{ marginRight: 20 }}
              />
            </div>
            <div>
              <Button
                classesNames={classNames(
                  classes.button_width,
                  classes.contained_button
                )}
                value={id ? "Update" : "Add"}
                onClick={() => {
                  if(id){
                    UpdateProduct();

                  }
                  else{
                    AddProduct();
                  }
                  // check();
                  // console.log(state?.result, "res", state?.image_file_web);
                }}
              />
            </div>
          </DialogActions>
        </Grid>
      </Grid>
    </div>
  );
}
