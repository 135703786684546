import {
  ContentCopyOutlined,
  ExpandMore,
  PictureAsPdfOutlined,
} from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  StyledEngineProvider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import InputField from "ui-components/TextFeild";
import { defaultLabelColors } from "utils/constants";

export default function ShopTiming(props: any) {
  const { classes, panel_head, from_vendors, details } = props;
  // const dispatch = useDispatch<any>();
  // const miniShop = useSelector((state: RootState) => state.transporterSlice);
  // const vendorSlice = useSelector((state: RootState) => state.vendors);
  // React.useEffect()
  const weekDays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

function getDayName(weekDayNumber:any) {
  return weekDays[weekDayNumber - 1];
}
  return (
    <StyledEngineProvider injectFirst>
      <Accordion
        defaultExpanded
        // expanded={
        //   from_vendors ? vendorSlice.panels.status : miniShop.panels.status
        // }
        className={classNames(classes.accordion_container)}
      >
        <AccordionSummary
          className={classNames(classes.accordion_head_container)}
          expandIcon={
            <ExpandMore
              className={classes.accordion_icons}
              style={{ fontSize: 24 }}
              // onClick={() =>
              //   dispatch(
              //     from_vendors
              //       ? toggleStatus(!vendorSlice.panels.status as any)
              //       : handleChangeStatus(!miniShop.panels.status as any)
              //   )
              // }
            />
          }
        >
          <span
            // onClick={() =>
            //   dispatch(
            //     from_vendors
            //       ? toggleStatus(!vendorSlice.panels.status as any)
            //       : handleChangeStatus(!miniShop.panels.status as any)
            //   )
            // }
            className={classNames(classes.accordion_heading)}
            style={{ flex: 1 }}
          >
            {panel_head}
          </span>
          {/* <ContentCopyOutlined
              style={{ marginRight: 10 }}
              className={classNames(classes.accordion_icons)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
            <PictureAsPdfOutlined
              style={{ marginRight: 10 }}
              className={classNames(classes.accordion_icons)}
              onClick={(e) => {
                e.stopPropagation();
              }}
            /> */}
        </AccordionSummary>

        <AccordionDetails className={classes.accordion_detail_padding}>
          {/* <Grid container spacing={4}>
            <Grid xs={12} md={12} item>
              <InputField
                fullWidth
                value={details?.addres ?? "- - -llorem*3adjsakdkjadhksajlkdjsakljdksajdkjsakmdsamcxkamckmsakcksackamskcakclksaldk;sa"}
                input_label={"Shop Address"}
                variant={"outlined"}
                multiline
                rows={5}
                InputProps={{
                  readOnly: true,
                }}
                onChange={(e: any) => {}}
                inputProps={{
                  className: classNames(classes?.input_feild, "Roboto-Medium"),
                  title: details?.shop_desc,
                }}
                classes={classes}
              />
            </Grid>
            
            
             
            </Grid> */}
         
          <TableContainer>
      <Table style={{ minWidth: "max-content", width: "100%" }}>
        <TableHead>
          <TableRow className={classes?.background_light_gray}>
            <TableCell
              className={classNames(
                classes?.table_head,
                "padding-10",
                "RobotoCondensed-Medium"
                
              )}
              style={{ width: 120 }}
            >
              Day
            </TableCell>
            <TableCell
              className={classNames(
                classes?.table_head,
                "padding-10",
                "RobotoCondensed-Medium"
              )}
              style={{ width: 120 }}
            >
              Opening Time
            </TableCell>
            <TableCell
              className={classNames(
                classes?.table_head,
                "padding-10",
                "RobotoCondensed-Medium"
              )}
              style={{ width: 120 }}
            >
              Closing Time
            </TableCell>
            <TableCell
              className={classNames(
                classes?.table_head,
                "padding-10",
                "RobotoCondensed-Medium"
              )}
              style={{ width: 120 }}
            >
              Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {details?.shop_timings?.map((timing:any, index:any) => (
            <TableRow
              key={timing?.id}
              // style={{
              //   background: index % 2 ? "#f6f6f6" : "#fff",
              //   borderBottom: index === details.shop_timings.length - 1 ? "1px solid #c5c5c5" : "",
              // }}
            >
              <TableCell
                className={classNames(
                  classes?.table_head,
                  "padding-10",
                  "RobotoCondensed-Medium"
                )}
                style={{ width: 120 }}
              >
                {getDayName(timing?.week_day_number)}
              </TableCell>
              <TableCell
                className={classNames(
                  classes?.table_head,
                  "padding-10",
                  "RobotoCondensed-Medium"
                )}
                style={{ width: 120 }}
              >
                {timing?.opening_time?moment(timing?.opening_time,"HH:mm").format("HH:mm") :""}
              </TableCell>
              <TableCell
                className={classNames(
                  classes?.table_head,
                  "padding-10",
                  "RobotoCondensed-Medium"
                )}
                style={{ width: 120 }}
              >
                {
                // timing?.closing_time
                timing?.closing_time?moment(timing?.closing_time,"HH:mm").format("HH:mm") :""

                }
              </TableCell>
              <TableCell
                className={classNames(
                  classes?.table_head,
                  "padding-10",
                  "RobotoCondensed-Medium"
                )}
                style={{ width: 120 ,color:timing?.is_close ? defaultLabelColors?.red :  defaultLabelColors?.green}}
              >
                {timing?.is_close ? "Closed" : "Open"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        </AccordionDetails>
      </Accordion>
    </StyledEngineProvider>
  );
}
