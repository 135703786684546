import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiServices from "services/RequestHandler";
import { key_to_label } from "utils/constants";
import _ from "lodash";
import moment from "moment";
export interface NotificationState {
  manual_notif: boolean;
  auto_birthday_notif: boolean;
  auto_push_notif: boolean;
  all_notifications: any;
  paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  activity: boolean;
}
interface APIParams {
  end_point: string;
}
const initialState: NotificationState = {
  manual_notif: true,
  all_notifications: [],
  auto_birthday_notif: false,
  auto_push_notif: false,
  paginate_data: null,
  activity: false,
};

export const allNotifications = createAsyncThunk(
  "orders/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "notifications"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const notificationSlicer = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    handleToggleManualNotif: (state: NotificationState, action: any) => {
      state.manual_notif = action.payload;
    },
    handleToggleBirthdatNotif: (state: NotificationState, action: any) => {
      state.auto_birthday_notif = action.payload;
    },
    handleToggleAutoPushNotif: (state: NotificationState, action: any) => {
      state.auto_push_notif = action.payload;
    },
    togglePanels: (state: NotificationState, action: any) => {
      state.manual_notif = action.payload;
      state.auto_birthday_notif = action.payload;
      state.auto_push_notif = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(allNotifications.pending, (state, { payload }) => {
      state.activity = true;
    });
    builder.addCase(allNotifications.fulfilled, (state, { payload }) => {
      // let keys = _.groupBy(payload?.data, "grouped_date");
      // let payload_length = Object.keys(keys).length;
      let payload_length = payload?.data?.length;
      let data = payload?.data;
      let final_data = [];
      // console.log("<<< IS_GROUPED >>> ", keys);

      for (let index = 0; index < payload_length; index++) {
        const item = data?.[index];
        final_data.push([
          item?.title ?? "- - -",
          item?.description ?? "- - -",
          key_to_label[item?.notification_type as any] ?? "- - -",
          moment(item?.created_at).format("DD-MMM-YYYY HH:mm"),
          item?.is_birthday_notif ? "Yes" : "No",
          `${item?.total_sent} user(s)`,
          `${item?.total_received} user(s)`,
          `${item?.total_read} user(s)`,
        ]);
      }
      state.all_notifications = final_data;
      console.log(payload?.total_count)
      state.paginate_data = {
        current_count: payload?.current_count,
        current_page: payload?.current_page,
        previous_count: payload?.previous_count,
        total_pages: payload?.total_pages,
        total_count: payload?.total_count,
      };

      state.activity = false;
    });
  },
});

export const {
  handleToggleManualNotif,
  handleToggleBirthdatNotif,
  handleToggleAutoPushNotif,
  togglePanels,
} = notificationSlicer.actions;

export default notificationSlicer.reducer;
