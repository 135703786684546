import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

// material-ui
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  Typography,
} from "@mui/material";
import {
  AssistantDirectionOutlined,
  ExpandLess,
  ExpandMore,
  FiberManualRecord,
} from "@mui/icons-material";
// project imports
import NavItem from "../NavItem";
import {
  FrankAnalyticsIcon,
  FrankCustomerIcon,
  FrankNotificationIcon,
  FrankOrderIcon,
  FrankPaymentIcon,
  FrankSettingIcon,
  FrankTransporterIcon,
  FrankVendorIcon,
} from "assets/images/FrankIcons";
import ViewCarouselIcon from '@mui/icons-material/ViewCarousel';
import classNames from "classnames";

// assets
// import { IconChevronDown, IconChevronUp } from "@tabler/icons";

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, classes }: any) => {
  // const customization = useSelector((state: any) => state.customization);

  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleClick = () => {
    setOpen(!open);
    setSelected(!selected ? menu.id : null);
  };

  const { pathname } = useLocation();
  const checkOpenForParent = (child: any, id: any) => {
    child.forEach((item: any) => {
      if (item.url === pathname) {
        setOpen(true);
        setSelected(id);
      }
    });
  };

  // menu collapse for sub-levels
  useEffect(() => {
    setOpen(false);
    setSelected(null);
    if (menu.children?.length > 0) {
      menu.children.forEach((item: any) => {
        if (item.children?.length) {
          checkOpenForParent(item.children, menu.id);
        }
        const currentIndex = pathname
          .toString()
          .split("/")
          .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
          setSelected(menu.id);
          setOpen(true);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, menu.children]);

  // menu collapse & item
  const menus = menu.children?.map((item: any) => {
    if(item.visibility === false) {
      return;
    }
    switch (item.type) {
      case "collapse":
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case "item":
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });
  const getDrawerIcons = (type: string) => {
    try {
      switch (type) {
        case "orders":
          return (
            <FrankOrderIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
        case "customers":
          return (
            <FrankCustomerIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        case "transporters":
          return (
            <FrankTransporterIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        case "vendors":
          return (
            <FrankVendorIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        case "payments":
          return (
            <FrankPaymentIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        case "notifications":
          return (
            <FrankNotificationIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        case "analytics":
          return (
            <FrankAnalyticsIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
        case "settings":
          return (
            <FrankSettingIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );
          case "banners":
          return (
            <ViewCarouselIcon
              className={classNames(classes?.dashboard_icon, "frank-icon")}
            />
          );

        default:
          return <AssistantDirectionOutlined style={{ marginRight: 10 }} />;
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ListItemButton
        sx={{
          mb: 1.5,
          alignItems: "flex-start",
          backgroundColor: level > 1 ? "transparent !important" : "inherit",
          py: 0.8,
          pl: 1,
          pr: 0,
          borderRadius: 1,
        }}
        onClick={handleClick}
      >
        {/* ICON */}
        <ListItemIcon sx={{ my: "auto", minWidth: !menu?.icon ? 18 : 36 }}>
          {getDrawerIcons(menu.id)}
        </ListItemIcon>

        <p style={{ marginBottom: 0, flex: 1 }}>{menu.title}</p>
        {/* {menu.id === "_notif" && <FiberManualRecord />} */}

        {open ? (
          <ExpandLess style={{ marginTop: "auto", marginBottom: "auto" }} />
        ) : (
          <ExpandMore style={{ marginTop: "auto", marginBottom: "auto" }} />
        )}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {menus}
        </List>
      </Collapse>
    </>
  );
};

NavCollapse.propTypes = {
  menu: PropTypes.object,
  level: PropTypes.number,
  classes: PropTypes.any,
};

export default NavCollapse;
