import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";
import { numberFormatWithoutCurrency } from "utils/helper";
import noImg from "assets/images/No_Image.jpg";
export interface Categorystate {
  all_Categories: any;
  all_Categories_data: any;
  all_Categories_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  all_attribute: any;
  all_attribute_data: any;
  all_attribute_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;
  all_spec: any;
  all_spec_data: any;
  all_spec_paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  selected_category: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
}

const initialState: Categorystate = {
  all_Categories: [],
  all_Categories_data: [],
  all_Categories_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  all_attribute: [],
  all_attribute_data: [],
  all_attribute_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },
  all_spec: [],
  all_spec_data: [],
  all_spec_paginate_data: {
    current_count: null,
    current_page: null,
    previous_count: null,
    total_pages: null,
    total_count: null,
  },

  selected_category: {},
  activity: true,
};

export const allCategory = createAsyncThunk("Cateogry", async (data: any) => {
  try {
    let response = await apiServices.postFromApi(
      data.end_point,
      {
        page: data.page,
        limit: data.limit,
        start_date: data?.start_date,
        end_date: data?.end_date,

        // ?page=${1}&=${50}
      },
      "category"
    );
    return response;
  } catch (err) {
    console.log(err);
  }
});
export const allAttribute = createAsyncThunk("Attribute", async (data: any) => {
  try {
    let response = await apiServices.postFromApi(
      data.end_point,
      {
        page: data.page,
        limit: data.limit,
        start_date: data?.start_date,
        end_date: data?.end_date,

        // ?page=${1}&=${50}
      },
      "attribute"
    );
    return response;
  } catch (err) {
    console.log(err);
  }
});
export const allSpec = createAsyncThunk("Spec", async (data: any) => {
  try {
    let response = await apiServices.getFromApi(
      data.end_point,
      // {
      //   page: data.page,
      //   limit: data.limit,
      //   start_date: data?.start_date,
      //   end_date: data?.end_date,

      //   // ?page=${1}&=${50}
      // },
      "spec"
    );
    return response;
  } catch (err) {
    console.log(err);
  }
});

export const orderSlice = createSlice({
  name: "calatlog",
  initialState,
  reducers: {
    setSelectedComments: (state: Categorystate, action: any) => {
      if (state.selected_category) {
        state.selected_category = action.payload;
      } else {
        state.selected_category = {};
      }
    },
    clearallCategory: (state: Categorystate) => {
      state.all_Categories = [];
      state.all_Categories_data = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle allCategory actions
      .addCase(allCategory.pending, (state: Categorystate) => {
        state.activity = true;
      })
      .addCase(allCategory.fulfilled, (state: Categorystate, { payload }) => {
        let category = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          if (item.is_deleted) continue;
          category.push([
            item?.id || "- - -",
            item?.category_name?.en ?? "- - -",
            item?.category_desc?.en || "- - -",
            {
              ui: (
                <div>
                  <img
                    src={item?.category_image?.web ?? noImg}
                    alt="category.jpeg"
                    height={50}
                    width={100}
                    style={{ objectFit: "contain", cursor: "pointer" }}
                    className="category-img"
                  />
                </div>
              ),
            },
            item?.added_by ?? "- - -"
          ]);
        }
        state.all_Categories = category;
        state.all_Categories_data = payload?.data;
        state.all_Categories_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })

      // Handle allAttribute actions
      .addCase(allAttribute.pending, (state: Categorystate) => {
        state.activity = true;
      })
      .addCase(allAttribute.fulfilled, (state: Categorystate, { payload }) => {
        let category = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          if (item.is_deleted) continue;
          category.push([
            item?.id || "- - -",
            moment(
              `${item?.created_at}`.endsWith('Z')
                ? item?.created_at
                : item?.created_at + 'Z',
            ).format('DD/MM/YYYY HH:mm'),
            item?.attribute_name?.en ?? "- - -",
            item?.attribute_count ?? "- - -",
            item?.added_by ?? "- - -",
          ]);
        }
        state.all_attribute = category;
        state.all_attribute_data = payload?.data;
        state.all_attribute_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      // Handle spec actions
      .addCase(allSpec.pending, (state: Categorystate) => {
        state.activity = true;
      })
      .addCase(allSpec.fulfilled, (state: Categorystate, { payload }) => {
        let category = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data[index];
          if (item.is_deleted) continue;
          category.push([
            item?.id || "- - -",
            moment(
              `${item?.created_at}`.endsWith('Z')
                ? item?.created_at
                : item?.created_at + 'Z',
            ).format('DD/MM/YYYY HH:mm'),
            item?.specification_name?.en ?? "- - -",
            item?.specification_values?.length ?? "0",
            item?.added_by ?? "- - -",
          ]);
        }
        state.all_spec = category;
        state.all_spec_data = payload?.data;
        state.all_spec_paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      });
  },
});


export const { setSelectedComments, clearallCategory } = orderSlice.actions;

export default orderSlice.reducer;
