import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { Grid } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import React from "react";
import SelectField from "ui-components/SelectField";

export default function ProfileInfo(props: any) {
  const {
    classes,
    // is_d2c_business,
    details,
    handleSelect,
    // e_merchant,
  } = props;
  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      enable_status: false,
      enable_verification: false,
    }
  );

  return (
    <Grid container spacing={2}>
      <Grid
        xs={12}
        md={3}
        item
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img
          src={
            details?.profile_pic ??
            "https://cdn3.iconfinder.com/data/icons/avatars-flat/33/man_5-512.png"
          }
          alt="profile-pic"
          style={{
            width: "100%",
            objectFit: "contain",
            height: 300,
            border: "1px solid #aeaeae",
            borderRadius: 5,
          }}
        />
        <p className={classNames(classes.profile_name)}>
          {`${details?.name ?? "- - -"}`}
        </p>
      </Grid>
      <Grid xs={12} md={9} item>
        <Grid container spacing={2}>
          <Grid xs={12} md={6} item>
            <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                ID
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.id ?? "- - -"}
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Name
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {/* {"C2C Minishop"} */}
                {`${details?.name ?? "- - -"} `}
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Date of Birth
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.date_of_birth
                  ? moment(details?.date_of_birth).format("DD/MM/YYYY")
                  : "- - -"}
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Created at{" "}
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.created_at
                  ? moment(details?.created_at).format("DD/MM/YYYY")
                  : "- - -"}
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Shop Configure
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.is_shop ? details?.shop_name ?details?.shop_name:`${details?.first_name}'s shop` : "No"}
              </div>
            </div>
            {/* <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Address
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.address ?? "- - -"}
              </div>
            </div> */}
            {/* <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                shop Description
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.shop_desc ?? "- - -"}
              </div>
            </div> */}
            {/* <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Country
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.country ?? "- - -"}
              </div>
            </div> */}
            <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Phone
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {`${details?.phone ?? "- -"}`}
              </div>
            </div>
            <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Email
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.email ?? "- - -"}
              </div>
            </div>

            {/* <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                OTP Verification
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.otp_verified ?? "- - -" ? "verified" : "Not verifed"}
              </div>
            </div> */}

            {/* <div style={{ display: "flex", marginLeft: 15, marginBottom: 4 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Shop Description
              </div>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                {details?.shop_desc ?? "- - -"}
              </div>
            </div> */}
          </Grid>
          <Grid xs={12} md={6} item>
            <div style={{ display: "flex", marginLeft: 15, marginBottom: 15 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Manage Status
                <br />
                {/* update pending */}
              </div>
              <div
                style={{ flex: 1, display: "flex", alignItems: "center" }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                <div style={{ flex: 1 }}>
                  <SelectField
                    disableClearable
                    isDisabled={!state.enable_status}
                    name={"is_active"}
                    value={
                      //   details.is_active?
                      //   e_merchant?.selected_is_active
                      {
                        label: details?.is_active ? "Active" : "Inactive",
                        value: details?.is_active ?? false,
                      }
                    }
                    handleSelect={handleSelect}
                    options={[
                      { label: "Active", value: true },
                      { label: "Inactive", value: false },
                    ]}
                  />
                </div>
                {state.enable_status ? (
                  <LockOpenOutlined
                    style={{ color: "gray" }}
                    onClick={() =>
                      setState({ enable_status: !state.enable_status })
                    }
                  />
                ) : (
                  <LockOutlined
                    style={{ color: "gray" }}
                    onClick={() =>
                      setState({ enable_status: !state.enable_status })
                    }
                  />
                )}
              </div>
            </div>

            <div style={{ display: "flex", marginLeft: 15, marginBottom: 30 }}>
              <div
                style={{ flex: 1 }}
                className={classNames(
                  classes.card_label_style,
                  "Roboto-Medium"
                )}
              >
                Manage Verification
                <br />
              </div>
              <div
                style={{ flex: 1, display: "flex", alignItems: "center" }}
                className={classNames(
                  classes.card_value_style,
                  "Roboto-Medium"
                )}
              >
                <div style={{ flex: 1 }}>
                  <SelectField
                    disableClearable
                    isDisabled={!state.enable_verification}
                    handleSelect={handleSelect}
                    value={
                      // details?.is_verified
                      {
                        label: details.is_verified
                          ? "Verified"
                          : "Not Verified",
                        value: details.is_verified,
                      }
                    }
                    name={"is_verified"}
                    options={[
                      { label: "Verified", value: true },
                      { label: "Not Verified", value: false },
                    ]}
                  />
                </div>
                {state.enable_verification ? (
                  <LockOpenOutlined
                    style={{ color: "gray" }}
                    onClick={() =>
                      setState({
                        enable_verification: !state.enable_verification,
                      })
                    }
                  />
                ) : (
                  <LockOutlined
                    style={{ color: "gray" }}
                    onClick={() =>
                      setState({
                        enable_verification: !state.enable_verification,
                      })
                    }
                  />
                )}
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
