import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { defaultLabelColors } from "utils/constants";
import { numberFormatWithoutCurrency } from "utils/helper";

export interface ShippingRateState {
  all_shipping_rates: any;
  all_shipping_rates_data: any;
  paginate_data?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  shipping_rates_users: any;
  shipping_rates_users_data: any;
  shipping_rates_users_paginate?: {
    current_count: any;
    current_page: any;
    previous_count: any;
    total_pages: any;
    total_count: any;
  } | null;

  activity: boolean;
}

interface APIParams {
  end_point: string;
  data?: any;
}

const initialState: ShippingRateState = {
  all_shipping_rates: [],
  all_shipping_rates_data: [],

  shipping_rates_users: [],
  shipping_rates_users_data: [],

  activity: false,
};

export const allShippingRates = createAsyncThunk(
  "shipping-rates/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "shipping_rates"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const singleShippingRatesUsers = createAsyncThunk(
  "shipping-rates/by-id/users",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "shipping_rates"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const shippingRateSlicer = createSlice({
  name: "shipping_rates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(allShippingRates.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(allShippingRates.fulfilled, (state, { payload }) => {
        let table_data = [];
        for (let index = 0; index < payload?.data.length; index++) {
          const item = payload?.data[index];
          const id = item?.id;
          const prefix = "RTE-"; // Assuming the prefix is constant

          // Get the portion of the ID after the prefix
          const idWithoutPrefix = id.slice(prefix.length);

          // Remove the date portion "20230911" (8 characters) from the ID
          const trimmedId = idWithoutPrefix.slice(8);
          table_data.push([
            `RTE-${trimmedId}` ?? "",
            item?.rate_name ? _.startCase(item?.rate_name) : "N/A",
            item?.rate_type ?? "N/A",
            item?.is_default ? "Yes" : "No",

            item?.applicable_date
              ? moment(item?.applicable_date).format("DD MMM YYYY")
              : "N/A",
            item?.expiry_date
              ? moment(item?.expiry_date).format("DD MMM YYYY")
              : "N/A",
            `${
              Number(item?.no_of_users) > 0
                ? `${item?.no_of_users} Store(s)`
                : "N/A"
            }`,
            moment(item?.created_at).format("DD MMM YYYY hh:mm A"),
            item?.created_by,
            item?.updated_by
              ? moment(item?.updated_at).format("DD MMM YYYY hh:mm A")
              : "- - -",

            item?.updated_by ? item?.updated_by : "- - -",
            {
              name: item?.id,
              hidden: true,
            },
          ]);
        }
        state.all_shipping_rates = table_data;
        state.all_shipping_rates_data = payload?.data;
        state.paginate_data = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      })
      .addCase(singleShippingRatesUsers.pending, (state, { payload }) => {
        state.activity = true;
      })
      .addCase(singleShippingRatesUsers.fulfilled, (state, { payload }) => {
        const rates = payload?.data;
        let table_data = [];
        for (let index = 0; index < rates?.length; index++) {
          const item = rates?.[index];
          table_data.push([
            item?.resource_id ?? "N/A",
            item?.resource_name ?? "N/A",
            item?.resource_phone ?? "N/A",
            item?.resource_location ?? "N/A",
            item?.applied_date
              ? moment(item?.applied_date).format("DD MMM YYYY")
              : "N/A",
            item?.applied_by ?? "N/A",
          ]);
        }
        state.shipping_rates_users = table_data;
        state.shipping_rates_users_data = payload?.data;
        state.shipping_rates_users_paginate = {
          current_count: payload?.current_count,
          current_page: payload?.current_page,
          previous_count: payload?.previous_count,
          total_pages: payload?.total_pages,
          total_count: payload?.total_count,
        };
        state.activity = false;
      });
  },
});

// export const {} = paymentSlicer.actions;

export default shippingRateSlicer.reducer;
