import { CommentOutlined } from "@mui/icons-material";
import {
  TabContext,
  TabList,
  TabPanel,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { Box, Button, Grid, Tab } from "@mui/material";
import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import * as React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import apiServices from "services/RequestHandler";
import { RootState } from "store";
import { allComments } from "store/commentsReducer/indext";
// import Button from "ui-components/Button";
import { defaultLabelColors, key_to_label } from "utils/constants";

export default function ActivityLogs(props: any) {
  const { classes, is_admin } = props;
  const params = useParams();
  const [_tabValue, _setValue] = React.useState("1");

  const dispatch = useDispatch<any>();
  const comment_slicer = useSelector((state: RootState) => state.comments);

  const [state, setState] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      more_detail: false,
      key: null,
    }
  );

  useEffect(() => {
    handleGetLogs();
    get_activity_logs();
  }, [props?.id]);

  const handleGetLogs = async (): Promise<void> => {
    try {
      // const cmnt_log = await apiServices.getFromApi(
      //   `comments/${props?.id}`,
      //   ""
      // );
      dispatch(allComments({ end_point: `comments/${props?.id}` }));
      // comment_slicer
      // setState({
      //   comments: cmnt_log?.data,
      //   // activity_logs: a_log?.data,
      // });
    } catch (error) {
      console.log(error);
    }
  };
  const _handleChange = (event: React.SyntheticEvent, newValue: string) => {
    _setValue(newValue);
  };
  /////
  const getMoreDetail = (idx: any) => {
    if (state?.key === idx) {
      // Close the component if it is already open
      setState({ key: null });
    } else {
      // Open the component with the new key
      setState({ key: idx });
    }

    ///
  };

  const get_activity_logs = async () => {
    try {
      if (!props?.id) return;
      // let response;
      // if(!is_admin)
      //   {
      //      response = await apiServices.getFromApi(
      //       `audit-logs/${props?.id}`,
      //       ""
      //     );

      //   }
      // else{
      //    response = await apiServices.getFromApi(
      //     `/audit-logs/auth-audit/${props?.id}`,
      //     ""
      //   );

      // }
      const response = await apiServices.getFromApi(
        `/audit-logs${is_admin ? "/auth-audit" : ""}/${props?.id}`,
        ""
      );

      let activity_logs_data = response?.data;
      //   ?.filter(
      //     (item: any) => item?.resource_id === props.id
      //   );

      let _data = activity_logs_data.filter(
        (i: any) =>
          ![
            "password_reset_token",
            "password_reset_expires",
            "updated_by",
            "created_at",
            "created_by",
            "modified_at",
            "modified_by",
            "permission_id",
            "module_id",
            "action_at",
            "updated_at",
            "step_no",
            "otp_verified",
            "otp_expire",
            "otp",
          ].includes(i.resource_column)
      );

      let final_array = [];

      for (let index = 0; index < _data.length; index++) {
        const item = _data[index];
        let text = ``;
        if (item.method_name === "INSERT") {
          text = `
           created this ${
             // item?.resource_table === "employees"
             //   ? "Employee"
             //   /:
             _.startCase(item?.resource_table.replace("c2c", "")) || "-"
           }`;

          final_array.push({
            ...item,
            time: item?.action_at,
            text,
            time_ago: moment
              .duration(moment(item?.action_at).diff(moment()))
              .humanize(true),
          });
        } else if (item.method_name === "UPDATE") {
          text =
            item.resource_column === "password" ||
            item.resource_column === "device_token"
              ? `
          <strong> Change</strong> ${_.startCase(
            item?.resource_table.replace("c2c", "")
          )}  ${">"} <strong> ${_.startCase(item?.resource_column)} 
                    </strong>
                    `
              : `
          <strong> Edited</strong> ${_.startCase(
            item?.resource_table.replace("c2c", "")
          )}  ${">"} <strong> ${_.startCase(item?.resource_column)} 
          </strong>  ${">"} 
          
          <b>${item?.resource_column === "service_method"?"":"from"}</b> ${
            item.is_date && item.resource_value_old
              ? moment(item.resource_value_old).format("lll")
              : item?.resource_column === "weight"
              ? key_to_label[item?.resource_value_old]
              : item?.resource_column === "service_method"
              ? formatServiceMethod("")
              : item?.resource_value_old === "true"
              ? "Yes"
              : item?.resource_value_old === "false"
              ? "No"
              : item?.resource_value_old ?? `""`
          } <b>${item?.resource_column === "service_method"?"":"to"}</b> ${
                  item.is_date && item.resource_value_new
                    ? moment(item.resource_value_new).format(`LLL`)
                    : item?.resource_column === "weight"
                    ? key_to_label[item?.resource_value_new]
                    : item?.resource_column === "service_method"
                    ? formatServiceMethod(item.resource_value_new)
                    : item?.resource_value_new === "true"
                    ? "Yes"
                    : item?.resource_value_new === "false"
                    ? "No"
                    : item?.resource_value_new ?? `""`
                }`;

          final_array.push({
            ...item,
            time: item?.action_at,
            text,
            time_ago: moment
              .duration(moment(item?.action_at).diff(moment()))
              .humanize(true),
          });
        } else {
          // final_array.push("");
        }
      }
      setState({
        activity_logs_data: final_array,
      });
    } catch (error) {
      console.error(error);
    }
  };

  //resource cloumn = service method handle JSon
  const formatServiceMethod = (jsonString: any) => {
    try {
      const obj = JSON.parse(jsonString);
      return Object.entries(obj)
        .filter(([method]) => method !== "self_delivery" && method !== "relais")
        .map(([method, roles]:any) => {
          const admin = roles.admin ? "Enable" : "Disable ";
          // const owner = roles.owner ? "Owner" : "No Owner";
          return `${key_to_label[method]}: ${admin}`;
  
        })
        .join(" | ");
    } catch (error) {
      return jsonString; // fallback to the original string if JSON parsing fails
    }
  };

  return (
    <div className={classes.main_layout_padding}>
      <TabContext value={_tabValue}>
        <Box
          sx={{
            background: defaultLabelColors.sand,
            borderRadius: 2,
            boxShadow:
              "0px 0px 0px -2px rgba(0,0,0,0.2),0px 0px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
            marginTop: props?.margin ?? 10,
          }}
        >
          <TabList
            onChange={_handleChange}
            TabIndicatorProps={{
              style: {
                backgroundColor: defaultLabelColors.main_orange,
                height: 4,
                marginLeft: 10,
              },
            }}
          >
            <Tab
              className={classNames(
                _tabValue === "1" ? classes?.active_tab : classes?.inactive_tab
              )}
              label="Activity Logs"
              value="1"
            />
            <Tab
              label="Comments"
              value="2"
              className={classNames(
                _tabValue === "2" ? classes?.active_tab : classes?.inactive_tab
              )}
            />
          </TabList>
        </Box>

        <TabPanel
          style={{
            background: "white",
            marginTop: 15,
            borderRadius: 6,
            boxShadow:
              "0px 0px 0px -2px rgba(0,0,0,0.2),0px 0px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
          }}
          value="1"
        >
          <Grid container>
            <Grid item xs={12}>
              <div
                style={{
                  height: state.activity_logs_data?.length >= 10 ? 400 : "auto",
                  overflow: "auto",
                  display:
                    state.activity_logs_data?.length > 0 ? "block" : "flex",
                }}
              >
                {state?.activity_logs_data?.length > 0 ? (
                  state?.activity_logs_data?.map((row: any, idx: any) => (
                    <Timeline
                      key={idx}
                      style={{
                        margin: 0,
                        padding: 0,
                      }}
                      sx={{
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                          margin: 0,
                        },
                      }}
                    >
                      <TimelineItem
                        style={{
                          minHeight: 40,
                        }}
                      >
                        <TimelineSeparator>
                          <TimelineConnector
                            style={{
                              width: 1,
                              background: "lightgrey",
                            }}
                          />
                          <TimelineDot color="error" />
                          <TimelineConnector
                            style={{
                              width: 1,
                              background: "lightgrey",
                            }}
                          />
                        </TimelineSeparator>
                        {/* <TimelineContent>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ marginRight: 20 }}>
                          <CommentOutlined />
                        </div>
                        <div>
                          <span style={{ fontFamily: "Roboto-Bold" }}>
                            {row?.action_by ?? "System"}
                          </span>{" "}
                          {}
                          <span style={{ fontFamily: "Roboto-Medium-Italic" }}>
                            “{row?.content ?? ""}”{" "}
                          </span>
                          —{" "}
                          {moment
                            .duration(moment(row?.created_at).diff(moment()))
                            .humanize(true)}
                        </div>
                      </div>
                    </TimelineContent> */}

                        <TimelineContent
                          sx={{
                            py: "12px",
                            px: 2,
                            display: "flex",
                            alignItems: "center",
                          }}
                          key={idx + 1}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ marginRight: 20 }}>
                              <CommentOutlined />
                            </div>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: `<strong 
                                 class="capitalize-css"
                                >${row?.action_by ?? "System"}</strong> ${
                                  row?.text
                                } - ${moment
                                  .duration(
                                    moment(row?.action_at).diff(moment())
                                  )
                                  .humanize(true)} `,
                              }}
                            />

                            {/* <div className="btn-link" onClick={() => {
                                if (state?.more_detail) {
                                  setState({ more_detail: false, key: idx });
                                } else {
                                  setState({ more_detail: true });
                                }
                              }}>More detail</div> */}
                          </div>
                          {state?.key === idx ? (
                            <div
                              style={{
                                display: "flex",
                                paddingLeft: 10,
                                gap: 15,
                              }}
                            >
                              <p>
                                <span className={classes?.more_detail_head}>
                                  Ip
                                </span>
                                : N/A
                              </p>
                              <p>
                                <span className={classes?.more_detail_head}>
                                  Platform
                                </span>
                                : N/A
                              </p>
                              <p>
                                <span className={classes?.more_detail_head}>
                                  Browser
                                </span>
                                : N/A
                              </p>
                              <p>
                                <span className={classes?.more_detail_head}>
                                  Location
                                </span>
                                : N/A
                              </p>
                            </div>
                          ) : null}
                          <Button
                            // id="basic-button"
                            // aria-controls={open ? "basic-menu" : undefined}
                            // aria-haspopup="true"
                            // aria-expanded={open ? "true" : undefined}
                            onClick={() => getMoreDetail(idx)}
                            className="btn-link"
                          >
                            {/* More Detail */}
                            {idx === state?.key
                              ? "Close Detail"
                              : "More Detail"}
                          </Button>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  ))
                ) : (
                  <div className={"no-records-found"}>No Activity</div>
                )}
              </div>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel
          style={{
            background: "white",
            marginTop: 15,
            borderRadius: 6,
            boxShadow:
              "0px 0px 0px -2px rgba(0,0,0,0.2),0px 0px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
          }}
          value="2"
        >
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                {comment_slicer?.all_Comments_data?.map(
                  (item: any, index: number) => (
                    <TimelineItem key={`COMMENT_${item.id}`}>
                      {comment_slicer?.all_Comments_data?.length ===
                      index + 1 ? (
                        <TimelineDot color="error" />
                      ) : (
                        <TimelineSeparator>
                          <TimelineDot color="error" />
                          <TimelineConnector />
                        </TimelineSeparator>
                      )}
                      <TimelineContent>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ marginRight: 20 }}>
                            <CommentOutlined />
                          </div>
                          <div>
                            <span style={{ fontFamily: "Roboto-Bold" }}>
                              {item?.added_by ?? ""}
                            </span>{" "}
                            commented{" "}
                            <span
                              style={{ fontFamily: "Roboto-Medium-Italic" }}
                            >
                              “{item?.content ?? ""}”{" "}
                            </span>
                            —{" "}
                            {moment
                              .duration(moment(item?.created_at).diff(moment()))
                              .humanize(true)}
                          </div>
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  )
                )}
              </Timeline>
            </Grid>
          </Grid>
        </TabPanel>
      </TabContext>
    </div>
  );
}
