import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "config";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  return (
    <ButtonBase disableRipple disabled component={Link} to={config.defaultPath}>
      <svg
        width="100%"
        height="100px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 992.89 653.71"
      >
        <defs>
          <style
            dangerouslySetInnerHTML={{
              __html: `
            .cls-1{fill:#ffffff;}
            .cls-2{font-size:126.28px;fill:#ffffff;font-family:Raleway-Italic, Raleway;font-style:italic;}
            .cls-3{letter-spacing:-0.03em;}.cls-4{letter-spacing:0em;}
            .cls-5{letter-spacing:-0.02em;}.cls-6{letter-spacing:-0.03em;}
            .cls-7{letter-spacing:-0.01em;}.cls-8{letter-spacing:-0.02em;}
            `,
            }}
          />
        </defs>

        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <polygon
              className="cls-1"
              points="32.31 272.68 154.1 240.05 152.58 211.12 0 252 13.41 508.74 44.21 500.49 39.02 402.71 141.3 375.3 139.9 348.17 37.61 375.58 32.31 272.68"
            />
            <polygon
              className="cls-1"
              points="940.48 0 906.96 8.98 797.59 182.39 790.38 40.58 759.6 48.83 772.93 305.23 803.73 296.98 799.57 218.52 836.07 161.81 925.17 264.44 959.04 255.36 854.23 137.88 940.48 0"
            />
            <path
              className="cls-1"
              d="M697.08,65.58l10.58,199.57L556.39,102.92l-23.95,6.41s7.6,142.85,7.64,146.09a128.1,128.1,0,0,1-15.74,63.46,131,131,0,0,1-13.22,19.74,135.43,135.43,0,0,1-9.29,10.24,128.31,128.31,0,0,1-77.78,36.8,130.13,130.13,0,0,1-35.75-1.26,127.74,127.74,0,0,1-27.37-8,130.06,130.06,0,0,1-56.2-45.33l.48-.23A75.89,75.89,0,0,0,327.49,312a100.51,100.51,0,0,0,22.24-54.55,83.53,83.53,0,0,0-2.42-29.59,80.29,80.29,0,0,0-10.19-22.95,66,66,0,0,0-16.44-17.59,58.1,58.1,0,0,0-21.31-9.69,50.31,50.31,0,0,0-24.72.76L175.43,205l13.43,256.74,30.79-8.25-4.89-92.19,59.51-16,1.66-.77a161.56,161.56,0,0,0,76.34,62.22A158.36,158.36,0,0,0,380,414.88a161.43,161.43,0,0,0,47.25,2.05,164.19,164.19,0,0,0,25.46-4.68A158.88,158.88,0,0,0,515.42,379a162,162,0,0,0,20.09-20.54c1.53-1.9,3-3.84,4.47-5.78,1.66-2.26,3.28-4.54,4.82-6.86a159,159,0,0,0,26.68-86c.1-6.39-5.17-98-5.17-98L715.55,320.6l25.66-6.87L727.87,57.33ZM282,313.94l-19.52,5.23-49.24,13.2-5.49-106.7,66.72-17.88a25.42,25.42,0,0,1,13.35-.09,33.37,33.37,0,0,1,12.09,6.1,45.46,45.46,0,0,1,9.94,11.1,52.06,52.06,0,0,1,6.57,14.92,63.88,63.88,0,0,1,1.8,23.17,74,74,0,0,1-5.77,22.26,58.41,58.41,0,0,1-12.36,18.16,42.17,42.17,0,0,1-7.57,5.94A39.22,39.22,0,0,1,282,313.94"
            />
            <path
              className="cls-1"
              d="M382.57,363l8.62-31.76,40.5-156.79c13.54,33.87,53.31,120.09,64.41,153a133.34,133.34,0,0,0,9.29-10.24,131,131,0,0,0,13.22-19.74L442.69,133.38l-26.35,7.06L355.2,355a127.15,127.15,0,0,0,27.37,8"
            />
          </g>
        </g>
      </svg>
    </ButtonBase>
  );
};

export default LogoSection;
